<script>
/* eslint-disable */
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import QuotePricingProgress from '@/components/rfq/QuotePricingProgress'
import RfqTimer from './rfq-view/rfq-timer'
import ImageLightbox from '@/components/image-lightbox/image-lightbox'
import Axios from 'axios'
import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import PdfViewer from '../../../components/utility/pdf-viewer'
import SupplierPrice from '../../../components/rfq/models/SupplierPrice'
import PurchaseDetail from '../../../components/purchase-detail.vue'
import RepairerPartListView from './rfq-view/part-list'
import RepairerPartListViewOrdered from './rfq-view/part-list-ordered'
import FileUpload from '../../../views/repairers/rfq/rfq-view/file-upload'
import CustomerAccountDeliver from '../../../components/CustomerAccountDeliver'
import { mapActions, mapGetters } from 'vuex'
import psLabelValue from '@/components/ps-label-value.vue'
import AdditionalInfo from './rfq-view/additional-info'
import StickyHeader from './rfq-view/rfq-view-sticky-header'
import DatePicker from '@/components/datePicker'
import imageGallery from '../../../components/image-lightbox/image-gallery'
import Chat from '../../../components/utility/chat/chat'
import * as dayjs from 'dayjs'
import { VPerfectSignature } from 'v-perfect-signature'
import { VueEditor } from 'vue2-editor'
import { subscribeToRfqChannel } from '@/centrifuge'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import { UAParser } from 'ua-parser-js'
import RfqImportPricing from './rfq-view/repair-rfq-import-quote-pdf'
import BestPriceCountdown from '../../../components/rfq/best-price-countdown.vue'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: 'rfq-view',
  data: function () {
    return {
      timestampInterval: null,
      isShowImportWizard: false,
      isShowPdfNR: false,
      selectedNRPDFUrl: null,
      currentNRSuppId: null,
      importedPDF: null,
      currentPDFFile: {
        id: null,
        src: null
      },
      importedParts: [
        // {
        //   "name": "FRONT BUMPER",
        //   "number": "FI-71777555",
        //   "qty": "1",
        //   "rrp": "843.13",
        //   "price": "758.82",
        //   "stockStatus": 4
        // },
        // {
        //   "name": "RADIATOR GRILLE",
        //   "number": "FI-156054309",
        //   "qty": "1",
        //   "rrp": "259.65",
        //   "price": "233.68",
        //   "stockStatus": 4
        // },
        // {
        //   "name": "MOULDING",
        //   "number": "FI-71777751",
        //   "qty": "1",
        //   "rrp": "79.43",
        //   "price": "71.49",
        //   "stockStatus": 4
        // },
        // {
        //   "name": "MAKE MEDALLION",
        //   "number": "FI-50521448",
        //   "qty": "1",
        //   "rrp": "84.16",
        //   "price": "75.74",
        //   "stockStatus": 1
        // },
        // {
        //   "name": "MAKE MEDALLION",
        //   "number": "FI-50521448",
        //   "qty": "1",
        //   "rrp": "84.16",
        //   "price": "75.74",
        //   "stockStatus": 1
        // }
      ],
      searchFilter: '',
      nonReturnableSelectedParts: {},
      nonReturnableSettings: {},
      selectedSchemeIndex: -1,
      isOpenNonReturnalbleModal: false,
      supplierNonReturnableId: null,
      isEmptySignature: true,
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      editorToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{
          align: ''
        }, {
          align: 'center'
        }, {
          align: 'right'
        }, {
          align: 'justify'
        }], [{
          list: 'ordered'
        }, {
          list: 'bullet'
        },], [{
          indent: '-1'
        }, {
          indent: '+1'
        }], // outdent/indent
        [{
          color: []
        }],
        ['clean'] // remove formatting button
      ],
      isSavingSelectedParts: false,
      savedSelectedParts: [],
      isClosedPreviewImage: false,
      isShowRequestRFQUpdate: false,
      isOnHoverOptions: false,
      partsSelectedByBestValue: [],
      partsSelectedByAllSupps: [],
      partsSelectedByInStock: [],
      sortByPartNameAsc: false,
      sortByPartNameDesc: false,
      sortByQtyAsc: false,
      sortByQtyDesc: false,
      sortByRRPAsc: false,
      sortByRRPDesc: false,
      activeTab: 'Vehicle Details',
      isEditPartList: false,
      isEditVehicleDetails: false,
      classTabContent: 'tab-content',
      isHiddenOptions: true,
      isShowStickyHeader: false,
      steps: [
        { label: 'Received Quote' },
        { label: 'Submitted' },
        { label: 'Priced' },
        { label: 'Parts Ordered' },
        { label: 'Parts Received' },
        { label: 'Paid' },
        { label: 'Completed' },
      ],
      deletePartsIds: [],
      isClaimNumberFieldFocused: false,
      isExtensionActive: false,
      rfq: {
        id: null,
        type: 'Manual',
        comments: '',
        estimator: '',
        pricesUpdateRequest: '',
        quoteOpenDays: null,
        purchasePriceSelector: null,
        secondRequestBestPrice: null,
        secondRequestBestPriceTimeEnd: null,
        thirdRequestBestPrice: null,
        thirdRequestBestPriceTimeEnd: null,
        vehicle: {
          id: null,
          rego: '',
          vin: '',
          make: '',
          model: '',
          series: '',
          dom: '',
          colour: '',
          transmission: 'Automatic',
          odometer: '',
          claimNumber: '',
          insurer: '',
        },
        statusId: 1,
        status: '',
        parts: [],
        images: [],
        files: [],
        suppliers: [],
        parts_to_suppliers: [],
        parentRfqPartsToSuppliers: [],
        parentRfqParts: [],
        parentRfqManualParts: [],
        additionalRfqPartsToSuppliers: [],
        additionalRfqParts: [],
        additionalRfqManualParts: [],
        additionalRfqPartsOrdered: [],
        additionalOrders: [],
        additionalRfqfor: null,
        dueCountdown: 0,
        cuttOff: '',
        orders: [],
        partsOrdered: [],
        isFromRepairshop: false,
        isAskForPaintCode: false,
        interval: null,
        paintCodeForRepair: null,
        isAlternate: false,
        directOrder: {
          comment: '',
        },
        properties: {
          isSentAsDirectOrder: false,
          isCanPinImages: false,
          isAskForPartsDiagram: false,
        }
      },
      selectedSuppliers: [],
      supplierPrice: {},
      expiredSuppsIds: [],
      expiredSuppsCheckAll: false,
      selectedPriceSet: '', // allSupplier // partsInStock // selection
      lastSelectedPriceSet: '',
      lastSelectedParts: [],
      orderedPriceSets: [],
      priceSets: [
        { name: 'singleSupplier', label: 'Best Value Single Supplier' },
        { name: 'allSupplier', label: 'Best Value All Supplier' },
        { name: 'partsInStock', label: 'Best Value Parts in Stock' },
        { name: 'selection', label: 'Order Selected Parts' },
      ],
      offers: [],
      schemeImage: null,
      _chat: null,
      chatMessages: [],
      isShowPdf: false,
      isShowDiagram: false,
      documentHeight: 0,
      documentWidth: 0,
      areaForOrderButton: 0,
      onlineStatusIntervalId: null,
      chatSupplierIsOnline: false,
      chatTypers: {},
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'currentCompany/getDetails',
      currentUser: 'currentUser/getCurrentUserInfo',
      currentRFQ: 'repairer/rfq/getCurrentRFQ',
      quoteOpenDaysForRequest: 'quoteOpenDaysForRequest',
      getInsurers: 'repairer/supplier/getInsurers',
      gettersAllRFQs: 'repairer/rfq/getAllRFQs',
    }),
    isHasOEMSupp() {
      return _.some(this.supplierPrice?.orderedSuppliers, (s) => {
        return s.isSupplierTypeOEMPAR
      })
    },
    isHasNRSuppParts () {
      return !_.isEmpty(this.supplierPrice.orderedSuppliers) && !!_.find(this.supplierPrice.orderedSuppliers, (s) => {
        return s.isNonRegistered
      })
    },
    additionalMenu () {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllRFQs, (r) => {
        return _.startsWith(_.trim(_.toLower(r.QPNumber)), _.trim(_.toLower(this.searchFilter)))
      })
      let result = []
      _.forEach(data, (itm) => {
        let label = itm.QPNumber
        if (itm.rego) {
          label += ' - '
        }

        if (itm.rego) {
          label += itm.rego + ' '
        }

        result.push({
          value: itm.id,
          label: label,
          QPNumber: itm.QPNumber,
          isSupplement: itm.isSupplement,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds = []
      let jobViewOptions = []
      _.forEach(result, (r) => {
        if (!_.includes(addedJobViewIds, r.jobViewId)) {
          let l = 'JOB VIEW'
          if (r.QPNumber) {
            l = r.QPNumber + ' - JOB VIEW'
          }
          if (r.isSupplement || r.value != r.jobViewId) {
            if (result.length == 1) {
              jobViewOptions.push({
                value: r.value,
                label: l,
                isJobView: true
              })
              addedJobViewIds.push(r.jobViewId)
            }
          } else {
            jobViewOptions.push({
              value: r.value,
              label: l,
              isJobView: true
            })
            addedJobViewIds.push(r.jobViewId)
          }
        }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    isDirectOrder () {
      return this.rfq?.properties?.isSentAsDirectOrder
    },
    isMobileOnly () {
      return isMobileOnly
    },
    isTablet () {
      return isTablet
    },
    computedSelectedNRSupps () {
      return _.filter(this.selectedSuppliers, (s) => {
        return _.some(s.selectedParts, (prtId) => {
          return s?.parts?.[prtId]?.nonReturnable
        })
      })
    },
    isShowIBSOptions () {
      return this.rfq?.extQps?.sourceSystem === 'PARTSBRIDGE_IBODYSHOP' && this.isExtensionActive && (!!(this.isRFQSubmitted && (_.find(this.supplierPrice?.orderedSuppliers, (s) => {return Number(s?.priceTotal)}) || !_.isEmpty(this.supplierPrice?.rrps))) || (this.isRFQOrdered) || (this.isRFQPriced))
    },
    isSentUpdate () {
      return this.rfq?.pricesUpdateRequest == 'UpdateRequested'
    },
    isReceivedUpdate () {
      return this.rfq?.pricesUpdateRequest == 'UpdateReceived'
    },
    expiredSupps () {
      return _.filter(this.supplierPrice?.orderedSuppliersForRequestUpdate, (s) => {
        return this.isSupplierExpired(s)
      })
    },
    isShowSelectedPartSection () {
      let supplier = this.OEMSupplier
      return !(supplier && !supplier.isRfqToSupplierStatusDecline && ((this.supplierPrice.selectedSupplierId === supplier.id && this.supplierPrice.orderedSuppliers.length > 1) || this.isSelectedAllParts(supplier)) && this.isRFQPriced)
    },
    isShowChat () {
      return !_.isEmpty(this.chatMessages)
          || (!_.isEmpty(this.OEMSupplier) && (this.isRFQOrdered || this.isRFQCancelled))
          || (this.isDirectOrder && this.isRfqSentToSingleSupplier)
    },
    getUserNameForChat () {
      if ((this.isDirectOrder && this.isRfqSentToSingleSupplier)) {
        return this.getSingleSupplier?.name
      } else if (this.OEMSupplier && this.OEMSupplier.name) {
        return this.OEMSupplier.name
      }
      return ''
    },
    OEMSupplier () {
      return this.supplierPrice.OEMSupplier
    },
    isHasSchemeImage () {
      return !!_.find(this.rfq.files, (f) => {
        return f.type === 'scheme'
      })
    },
    computedSchemeImages () {
      return _.filter(this.rfq.files, (f) => {
        return f.type === 'scheme'
      })
    },
    computedCustomCountForTab () {
      let counts = {
        'part-list': 0,
        images: 0,
        files: 0,
      }
      counts['part-list'] = this.supplierPrice?.parts?.length
      counts.images = this.rfq?.images?.length
      counts.files = this.rfq?.files?.length
      return counts
    },
    insurerList () {
      let data = _.cloneDeep(this.getInsurers)
      return _.orderBy(data, [(itm) => itm.toLowerCase().trim()], ['asc'])
    },
    isQuoteExpired () {
      return _.some(this.supplierPrice.selectedSuppliers, (supp) => {
        let flag = false
        if (supp.quoteValidTill && this.isRFQPriced) {
          let day1 = dayjs(supp.quoteValidTill)
          let day2 = dayjs.utc()
          let diff = day1.diff(day2, 'day')
          // if (diff <= 0) { //#274 hotfix
          //   bool = true;
          // }
          if (day1 < day2) {
            flag = true
          }
        }
        return flag
      })
    },
    computedRfqOrders () {
      let orders = {}
      _.forIn(this.rfq.orders, (order, orderId) => {
        orders[orderId] = order
      })
      _.forIn(this.rfq.parentOrders, (order, orderId) => {
        order.isParent = true
        orders[orderId] = order
      })
      _.forIn(this.rfq.additionalOrders, (order, orderId) => {
        order.isAdditional = true
        orders[orderId] = order
      })
      return orders
    },
    isCanRequestRFQUpdate () {
      let suppsFiltered = _.filter(this.supplierPrice.orderedSuppliers, (supp) => {
        return this.isSupplierExpired(supp)
      })
      return suppsFiltered.length > 0
    },
    isAlreadySentRequestRFQUpdate () {
      return _.some(this.supplierPrice.orderedSuppliers, (s) => {
        return (s.isSentRequestToUpdate && s.isSentRequestToUpdate === true)
      })
    },
    isCanRequestRfqUpdateToAll () {
      let suppsFiltered = _.filter(this.supplierPrice.orderedSuppliers, (supp) => {
        return this.isSupplierExpired(supp)
      })
      return suppsFiltered.length === this.supplierPrice.orderedSuppliers.length
    },
    getSingleSupplier () {
      if (!this.isRfqSentToSingleSupplier) {
        return null
      }
      return this.supplierPrice.orderedSuppliers[0]
    },
    isRfqSentToSingleSupplier () {
      if (this.rfq?.parts_to_suppliers) {
        return Object.keys(this.rfq.parts_to_suppliers).length === 1
      }
      return false
    },
    isRfqSentToMultipleSupplier () {
      if (this.rfq?.parts_to_suppliers) {
        return Object.keys(this.rfq.parts_to_suppliers).length > 1
      }
      return false
    },
    isAvailableOrderParts () {
      if (!this.isRFQPriced) {
        return false
      }
      if (this.isRfqSentToSingleSupplier) {
        return this.countOfSelectedParts >= this.countOfAvailableParts
      }
      return this.countOfSelectedParts >= this.countOfAvailableParts
      // && !_.isEqual(_.sortBy(this.selectedParts, ['partId']), _.sortBy(_.sortBy(this.partsSelectedByBestValue, ['partId'])))
      // && !_.isEqual(_.sortBy(this.selectedParts, ['partId']), _.sortBy(_.sortBy(this.partsSelectedByInStock, ['partId'])))
      // && !_.isEqual(_.sortBy(this.selectedParts, ['partId']), _.sortBy(_.sortBy(this.partsSelectedByAllSupps, ['partId'])))
    },
    countOfAvailableParts () {
      let count = 0
      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        if (s.countPartsForSelection > count) {
          count = s.countPartsForSelection
        }
      })
      return count
    },
    selectedParts () {
      let parts = []
      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        _.forEach(s.selectedParts, (id) => {
          parts.push({
            partId: id,
            suppId: s.id,
          })
        })
      })
      return parts
    },
    isSelectedNRSuppParts () {
      return !!_.find(this.supplierPrice.orderedSuppliers, (s) => {
        return !_.isEmpty(s.selectedParts) && s.isNonRegistered
      })
    },
    countOfSelectedParts () {
      let filteredSelectedParts = []
      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        _.forEach(s.selectedParts, (id) => {
          _.forIn(s.parts, (part, partId) => {
            if ((Number(partId) === 0 && part[id] && part[id].id && part[id].linkType !== 'withNoCost' && part[id].type !== 'delivery') || (part && part.id && Number(partId) === Number(id) && part.linkType !== 'withNoCost' && part.type !== 'delivery')) {
              filteredSelectedParts.push(id)
            }
          })
        })
      })
      return filteredSelectedParts.length
    },
    computedPaintCodeForRepair () {
      if (!this.rfq.isAskForPaintCode) {
        return ''
      }
      if (this.isRFQSubmitted) {
        return (this.rfq.paintCodeForRepair || '')
      }
      if (this.rfq.paintCodeForRepair == null) {
        return 'NO CODE PRVD'
      }
      return this.rfq.paintCodeForRepair
    },
    ableToSendListPrices () {
      if (!this.rfq.isFromRepairshop) {
        return false
      }
      if (!['Submitted', 'Priced', 'Ordered',].includes(this.rfq.status)) {
        return false
      }
      if (this.supplierPrice?.rrps) {
        for (let id in this.supplierPrice.rrps) {
          if (this.supplierPrice.rrps[id] && this.supplierPrice.rrps[id] > 0) {
            return true
          }
        }
      }
      if (this.isRFQSubmitted && _.some(this.supplierPrice.parts, (itm) => {
        return (!_.isEmpty(itm.numberBySupplier) || !_.isEmpty(itm.rrpBySupplier))
      })) {
        return true
      }

      return false
    },
    orderInstruction () {
      let orders = _.values(this.rfq.orders)
      if (!orders) {
        return ''
      }
      let order = orders.shift()
      if (!order) {
        return ''
      }
      return order.deliveryTo.instruction || ''
    },
    orderDateDue () {
      let orders = _.values(this.rfq.orders)
      if (!orders) {
        return ''
      }
      let order = orders.shift()
      if (!order) {
        return ''
      }
      return order.dateDue && order.dateDue !== 'null' ? order.dateDue : 'Next available run'
    },
    orderDateCreated () {
      let orders = _.values(this.rfq.orders)
      if (!orders) {
        return ''
      }
      let order = orders.shift()
      if (!order) {
        return ''
      }
      return order.dateCreated || ''
    },
    deliveryTo () {
      let result = ''
      if (!_.isEmpty(this.rfq.order) && !_.isEmpty(this.rfq.order.deliveryTo)) {
        result = this.rfq.order.deliveryTo
      } else if (!_.isEmpty(this.rfq.deliveryAddress)) {
        result = this.rfq.deliveryAddress
      } else if (!_.isEmpty(this.rfq.location)) {
        result = this.rfq.location
      }
      return result
    },
    viewRfqsTableLink () {
      let link = '/r/rfqs/#'
      link = link + _.toLower(this.rfq.status)
      return link
    },
    computedParts: function () {
      if (this.sortByPartNameAsc) {
        return _.sortBy(this.rfq.parts, 'name')
      } else if (this.sortByPartNameDesc) {
        return _.reverse(_.sortBy(this.rfq.parts, 'name'))
      } else if (this.sortByQtyAsc) {
        return _.sortBy(this.rfq.parts, 'qty')
      } else if (this.sortByQtyDesc) {
        return _.reverse(_.sortBy(this.rfq.parts, 'qty'))
      } else if (this.sortByRRPAsc) {
        return _.sortBy(this.rfq.parts, 'qty') //TODO пока нет столбца потом переделать
      } else if (this.sortByRRPDesc) {
        return _.reverse(_.sortBy(this.rfq.parts, 'qty'))
      }
      return this.rfq.parts
    },
    imageSource: function () {
      return this.rfq.images
    },
    isEditable: function () {
      return !!(this.isEditableVehicleDetails || this.isEditablePartList)
    },
    isEditablePartList: function () {
      return this.isEditPartList

    },
    isEditableVehicleDetails: function () {
      return this.isEditVehicleDetails
    },
    isCurrentTabVehicleDetails: function () {
      return this.$route.meta.currentTab === 'Vehicle Details' || this.activeTab == 'Vehicle Details'

    },
    isCurrentTabPartList: function () {
      return this.$route.meta.currentTab === 'Part List' || this.activeTab == 'Part List'

    },
    isRFQReceived: function () {
      return this.rfq.status === 'Received'

    },
    isRFQSubmitted: function () {
      return this.rfq.status === 'Submitted'

    },
    isRFQPriced: function () {
      return this.rfq?.status === 'Priced'

    },
    isRFQOrdered: function () {
      return this.rfq.status === 'Ordered'

    },
    isRFQCancelled: function () {
      return this.rfq.status === 'Cancelled'

    },
    isAlternate () {
      return (this.rfq.isAlternate === true)
    },
    isShowTransferListPrice () {
      if (!this.rfq.isFromRepairshop) {
        return false
      }
      let flag = false

      // Checking OEM supplier is priced
      for (let suppId in this.supplierPrice.suppliers) {
        let sup = this.supplierPrice.suppliers[suppId]
        if (sup && (sup.type == 'OEM' || sup.type == 'OEM-GEN') && sup.rfqToSupplierStatus == 'Priced') {
          flag = true
          break
        }
      }
      return this.isRFQSubmitted && flag
    },
    isShowSendToQuotingPackage1 () {
      if (!this.rfq.isFromRepairshop) {
        return false
      }
      if (this.isRFQSubmitted || this.isRFQPriced || this.isRFQOrdered) { //I've added this return function because #201.4
        return true

      }
      return false
      if (this.isRFQPriced) {

        let flag = false,
            flag2 = false,
            flag3 = false,
            oem = null

        // Checking OEM supplier is priced
        for (let suppId in this.supplierPrice.suppliers) {
          let sup = this.supplierPrice.suppliers[suppId]
          if (sup && (sup.type == 'OEM' || sup.type == 'OEM-GEN') && sup.rfqToSupplierStatus == 'Priced') {
            flag = true
            oem = sup
            break
          }
        }

        // Checking list prices is provided
        if (oem) {
          for (let pid in this.supplierPrice.rrps) {
            if (pid == 0) {
              continue
            }
            if (this.supplierPrice.rrps[pid] > 0) {
              flag2 = true
              break
            }
          }
        }

        // Checking prices is selected
        for (let suppId in this.supplierPrice.suppliers) {
          let sup = this.supplierPrice.suppliers[suppId]
          if (sup && sup.selectedParts.length > 0) {
            flag3 = true
            break
          }
        }

        return flag && flag2 && flag3

      }
      if (this.isRFQOrdered) {

        return true

      }
      if (this.isRFQSubmitted) {
        return true
      }
      return false
    },
    isShowSendToQuotingPackage2 () {
      if (!this.rfq.isFromRepairshop) {
        return false
      }
      if (this.isRFQPriced || this.isRFQOrdered) { //I've added this return function because #201.4
        return true

      }
      return false
      if (this.isRFQPriced) {

        let flag = false,
            flag2 = false,
            flag3 = false,
            oem = null

        // Checking OEM supplier is priced
        for (let suppId in this.supplierPrice.suppliers) {
          let sup = this.supplierPrice.suppliers[suppId]
          if (sup && (sup.type == 'OEM-GEN' || sup.type == 'OEM') && sup.rfqToSupplierStatus == 'Priced') {
            flag = true
            oem = sup
            break
          }
        }

        // Checking list prices is provided
        if (oem) {
          for (let pid in this.supplierPrice.rrps) {
            if (pid == 0) {
              continue
            }
            if (this.supplierPrice.rrps[pid] > 0) {
              flag2 = true
              break
            }
          }
        }

        // Checking prices is selected
        for (let suppId in this.supplierPrice.suppliers) {
          let sup = this.supplierPrice.suppliers[suppId]
          if (sup && sup.selectedParts.length > 0) {
            flag3 = true
            break
          }
        }

        return flag && flag2 && flag3

      }
      if (this.isRFQOrdered) {

        return true

      }
      return false
    },
    computedDueDate: function () {
      return this.rfq.dueCountdown
    },
    currentQPPStep: function () {
      let status = 1
      if (this.isRFQCancelled) {
        status = this.rfq.quotePricingProgress.position
      } else if (this.rfq.statusId > 0) {
        status = this.rfq.statusId
      }
      return status
    },
    pageTitle () {
      let r = 'Edit Quote'
      if (!_.isEmpty(_.trim(this.rfq.number))) {
        r += ` - ${this.rfq.number}`
      }
      if (!_.isEmpty(_.trim(_.toUpper(this.rfq.QPNumber)))) {
        r += ` | ${_.toUpper(this.rfq.QPNumber)}`
      }
      if (!_.isEmpty(_.trim(this.rfq.vehicle.rego)) || !_.isEmpty(_.trim(this.rfq.vehicle.make)) || !_.isEmpty(_.trim(this.rfq.vehicle.model))) {
        r += ' |'
        if (!_.isEmpty(_.trim(this.rfq.vehicle.rego))) {
          r += ` ${_.toUpper(this.rfq.vehicle.rego)}`
        }
        if (!_.isEmpty(_.trim(this.rfq.vehicle.make))) {
          r += ` ${_.toUpper(this.rfq.vehicle.make)}`
        }
        if (!_.isEmpty(_.trim(this.rfq.vehicle.model))) {
          r += ` ${_.toUpper(this.rfq.vehicle.model)}`
        }
      }

      return r
    },
    mobileOptionsClass () {
      if (this.pageTitle.length >= 62 && this.pageTitle.length <= 69) {
        return 'top-15'
      }
    },
    unviewedMessagesIds () {
      let ids = []
      for (let m of this.chatMessages) {
        if (m.messageId && !m.viewed && m.companyId != this.companyInfo.id) {
          ids.push(m.messageId)
        }
      }
      return ids
    },
  },
  filters: {
    maxFour (str) {
      switch (str) {
        case 'Received':
          return 'Rcvd'
        case 'Submitted':
          return 'Subm'
        case 'Priced':
          return 'Prcd'
        case 'Ordered':
          return 'Ordr'
        case 'Cancelled':
          return 'Canc'
        default:
          return str.substring(0, 4)
      }
    }
  },
  methods: {
    ...mapActions({
      getRFQById: 'repairer/rfq/getRFQById'
    }),
    endCountdown () {
      this.loadRFQ(this.rfq.id)
      console.log('endCountdown')
    },
    requestBestPrice () {
      Axios.post(`/ir/repairer/rfq/${this.rfq.id}/request-best-price`, {
        type: 2,
        rfqToSupplier: _.map(this.supplierPrice.orderedSuppliers, (s) => { return s.id })
      }).then((r) => {
        if (r.data?._status) {
          this.rfq.secondRequestBestPrice = true
          this.rfq.secondRequestBestPriceTimeEnd = dayjs().add(30,'minute').format('YYYY-MM-DD HH:mm:ss')
        }
      })
    },
    unlockRequestBestPrice (id) {
      Axios.post(`/ir/repairer/rfq/${this.rfq.id}/request-best-price`, {
        type: 3,
        rfqToSupplier: id
      })
    },
    lockRequestBestPrice (id) {
      Axios.post(`/ir/repairer/rfq/${this.rfq.id}/request-best-price`, {
        type: 4,
        rfqToSupplier: id
      })
    },
    selectedPdfNr (v) {
      this.selectedNRPDFUrl = v
      // this.selectedNRPDFUrl = v + '?at=' + localStorage.getItem('token');
      this.isShowPdfNR = true
    },
    setRrp (v) {
      this.supplierPrice.parts[v.index].rrp = v.rrp
    },
    setValueForOffer (v) {
      let suppIndex = v.suppIndex
      let offerInd = v.offerInd
      let prt = v.prt

      if (this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstPrice == null) {
        this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstPrice = prt.price
      }
      this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].price = prt.price

      if (this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstRrp == null) {
        this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].firstRrp = prt.rrp
      }

      this.supplierPrice.orderedSuppliers[suppIndex].offers[offerInd].rrp = prt.rrp
    },
    isOemGenSupp (id) {
      let suppIndex = _.findIndex(this.supplierPrice.orderedSuppliers, (s) => {
        return Number(s.id) === Number(id)
      })
      if (suppIndex !== -1) {
        return this.supplierPrice.orderedSuppliers[suppIndex].type === 'OEM-GEN'
      }
      return false
    },
    importedPartsForNRSupp (v) {
      this.importedParts = v.parts
      this.importedPDF = v.file
      this.currentNRSuppId = v.id
      this.currentPDFFile = v.pdfFile
    },
    getImportedParts () {
      let result = null
      if (this.importedParts.length < this.supplierPrice.parts.length) {
        let r = _.cloneDeep(this.importedParts)
        let diff = this.supplierPrice.parts.length - this.importedParts.length
        for (let i = 0; i < diff; i++) {
          r.push({
            'name': '',
            'number': '',
            'qty': '0',
            'rrp': '0',
            'price': '0',
            'stockStatus': null,
            isMatched: false,
            isAddedManually: true
          })
        }
        result = _.map(r, (itm) => {
          return {
            ...itm,
            isChecked: false,
          }
        })
      } else {
        result = _.map(_.cloneDeep(this.importedParts), (itm) => {
          return {
            ...itm,
            isChecked: false,
          }
        })
      }

      if (this.isHasOEMSupp) {
        let matchedByPartNumber = _.filter(_.cloneDeep(result), (itm) => {
          return !!_.find(this.supplierPrice.parts, (p) => {
            let oemComment = _.trim(this.supplierPrice.getPartNumber(p.id)).replaceAll(' ', '')
            let oeComment = itm?.oeNumber ? _.trim(itm?.oeNumber).replaceAll(' ', '') : ''

            if (this.isOemGenSupp(this.currentNRSuppId)) {
              oeComment = itm?.number ? _.trim(itm?.number).replaceAll(' ', '') : ''
            }

            if (!_.isEmpty(oemComment) && !_.isEmpty(oeComment) &&  _.includes(oemComment, oeComment)) {
              return true
            }
            return false
          })
        })

        if (!_.isEmpty(matchedByPartNumber)) {
          let indexes = {}
          _.forEach(result, (itm, ind) => {
            indexes[ind] = false
          })
          result = _.filter(result, (itm) => {
            return !_.find(matchedByPartNumber, (mItm) => {
              return mItm.fullDescription == itm.fullDescription &&
                  mItm.name == itm.name &&
                  mItm.number == itm.number &&
                  mItm.oeNumber == itm.oeNumber &&
                  mItm.price == itm.price &&
                  mItm.qty == itm.qty &&
                  mItm.rrp == itm.rrp &&
                  mItm.stockComment == itm.stockComment &&
                  mItm.stockStatus == itm.stockStatus
            })
          })
          _.forEach(matchedByPartNumber, (itm) => {
            let partIndex = _.findIndex(this.supplierPrice.parts, (p) => {
              let oemComment = _.trim(this.supplierPrice.getPartNumber(p.id)).replaceAll(' ', '')
              let oeComment = itm?.oeNumber ? _.trim(itm?.oeNumber).replaceAll(' ', '') : ''

              if (this.isOemGenSupp(this.currentNRSuppId)) {
                oeComment = itm?.number ? _.trim(itm?.number).replaceAll(' ', '') : ''
              }

              if (!_.isEmpty(oemComment) && !_.isEmpty(oeComment) &&  _.includes(oemComment, oeComment)) {
                return true
              }
              return false
            })
            if (partIndex !== -1) {
              itm.isMatched = true
              indexes[partIndex] = itm
            }
          })

          let finalResult = []
          let indexR = 0
          _.forIn(indexes, (value, ind) => {
            if (value) {
              finalResult.push(value)
            } else {
              finalResult.push(result[indexR])
              indexR++
            }
          })
          return finalResult
        }
      }
      return result
    },
    getOriginalParts () {
      if (this.importedParts.length > this.supplierPrice.parts.length) {
        let r = _.cloneDeep(this.supplierPrice.parts)
        let diff = this.importedParts.length - this.supplierPrice.parts.length
        for (let i = 0; i < diff; i++) {
          r.push({
            'name': '',
            id: null
          })
        }
        return r
      }
      return _.cloneDeep(this.supplierPrice.parts)
    },
    openContextMenu (v) {
      this.$refs.partList.openContextMenu(v.supplier, v.e)
    },
    redirectToRfqView (itm) {
      if (itm.isJobView) {
        this.$router.push({ name: 'RepairerJobView', params: { job_id: itm.value } })
      } else {
        this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: itm.value } })
      }
    },
    onEnter () {
      setTimeout(() => {
        let rfq = _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })

        if (rfq && !_.isEmpty(this.searchFilter)) {
          this.$router.push({ name: 'RepairerJobView', params: { job_id: rfq.value } })
          // this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: rfq.id } })
        }
      }, 500)

    },
    openPdf (v) {
      this.isShowPdf = v
      this.handleResize()
    },
    setExtensionActive () {
      console.log('EXTENSION ACTIVATED')
      this.isExtensionActive = true
    },
    sendPricingToIBS () {
      window.postMessage({
        type: 'partsbridge-IBODYSHOP_SEND_BACK_PRICING',
        data: {
          rfqId: this.rfq.id,
          qpsSystem: this.rfq?.extQps
        }
      })
    },
    createOrderInIBS () {
      window.postMessage({
        type: 'partsbridge-IBODYSHOP_CREATE_ORDER',
        data: {
          rfqId: this.rfq.id,
          qpsSystem: this.rfq?.extQps
        }
      })
    },
    onCloseChat () {
      if (this.unviewedMessagesIds.length > 0) {
        this.markMessagesAsViewed(this.unviewedMessagesIds)
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
    },
    onExpiredSuppsCheckAllChanged () {
      if (this.expiredSuppsCheckAll) {
        this.expiredSuppsIds.splice(0, this.expiredSuppsIds.length)
        _.forEach(this.expiredSupps, (s) => {
          this.expiredSuppsIds.push(s.supplierId)
        })
      } else {
        this.expiredSuppsIds = []
      }
    },
    onExpiredSuppsCheckChanged () {
      if (this.expiredSupps.length == this.expiredSuppsIds.length) {
        this.expiredSuppsCheckAll = true
      } else {
        this.expiredSuppsCheckAll = false
      }
    },
    isSelectedAllParts (supplier) {
      return supplier && supplier.hasOwnProperty('getPartsLength') && supplier?.selectedParts?.length === supplier.getPartsLength()
    },
    isDisabled (s) {
      return (this.nonReturnableSelectedParts?.[s.id]?.length !== this.getNonReturnableParts(s)?.length) || (s?.nonReturnableRules?.requireAcceptTncs && this.nonReturnableSettings?.[s.id] && !this.nonReturnableSettings?.[s.id]?.acceptanceOfTics)
          || (s?.nonReturnableRules?.requireESign && this.$refs['signaturePad-' + s.id]?.[0]?.isEmpty())
          || (s?.nonReturnableRules?.requireNameAndPosition && this.nonReturnableSettings?.[s.id] && (_.isEmpty(this.nonReturnableSettings[s.id].acceptedBy) || _.isEmpty(this.nonReturnableSettings[s.id].positionHeld)))
    },
    changedNonReturnableSelectedParts () {
      let partsCopy = _.cloneDeep(this.nonReturnableSelectedParts)
      this.$set(this, 'nonReturnableSelectedParts', partsCopy)
    },
    changedNonReturnableSettings () {
      let partsCopy = _.cloneDeep(this.nonReturnableSettings)
      this.$set(this, 'nonReturnableSettings', partsCopy)
    },
    declineOrAcceptNonReturnable (status, s, isLast = false) {
      const parser = new UAParser()
      let result = parser.getResult()
      let type = 'Desktop'
      if (this.isMobileOnly) {
        type = 'Phone'
      } else if (this.isTablet) {
        type = 'Tablet'
      }

      let signaturePad = this.$refs['signaturePad-' + s.id]?.[0]
      let signature = null
      if (signaturePad) {
        signature = signaturePad.toDataURL()
      }
      let data = {
        'rfqToSupplierId': s.id,
        'eSign': signature,
        'partsId': this.nonReturnableSelectedParts[s.id],
        status: status,
        acceptanceOfTics: this.nonReturnableSettings[s.id].acceptanceOfTics,
        acceptedBy: this.nonReturnableSettings[s.id].acceptedBy,
        positionHeld: this.nonReturnableSettings[s.id].positionHeld,

        type: type,
        system: result?.os?.name + ' ' + result?.os?.version,
        browser: result?.browser?.name + ' version ' + result?.browser?.version,
      }
      NProgress.start()
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/parts-non-returnable-agreement',
          data
      )
          .then(response => {
            if (response.data._status) {
              if (this.$refs['non-returnable-example-' + s.id]?.[0]) {
                this.$refs['non-returnable-example-' + s.id][0].hide()
              }
              if (status == 'accepted' && isLast) {
                this.orderCreate(this.supplierPrice.getSelectedPartsIds(), false)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })

      if (status == 'decline') {
        _.forEach(this.supplierPrice.selectedSuppliers, (sup) => {
          let isHasNonReturnableParts = _.some(sup.selectedParts, (prtId) => {
            return sup?.parts?.[prtId].nonReturnable
          })
          if (isHasNonReturnableParts) {
            this.nonReturnableSelectedParts[sup.id] = []
            this.nonReturnableSettings[sup.id] = {
              acceptanceOfTics: false,
              acceptedBy: '',
              positionHeld: ''
            }
            this.changedNonReturnableSettings()
            this.changedNonReturnableSelectedParts()
            if (this.$refs['non-returnable-example-' + sup.id]?.[0]) {
              this.$refs['non-returnable-example-' + sup.id][0].hide()
            }

            this.isOpenNonReturnalbleModal = false
          }
        })
      }
    },
    changedActiveSupplierId (v) {
      this.$refs.partList.setActiveSupplier(v)
    },
    onScroll () {
      if (this.activeTab !== 'Part List') {
        return
      }
      this.areaForOrderButton = window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth - document.getElementById('rfq-view-table')?.clientWidth

      let element = this.$refs.additionalInfo?.$el
      if (!element) {
        return
      }
      let element2 = document.getElementById('price-buttons')
      let rect = element.getBoundingClientRect()
      let rect2 = element2 ? element2.getBoundingClientRect() : null
      // console.log('rect2', rect2?.top)
      if (rect.top <= 150) {
        // let element3 = document.getElementById('rfq-view-table')
        // if (rect2.top < 377 && element3 && element3.scrollWidth > element3.clientWidth) {
        //   this.isShowStickyHeader = false
        // } else {
        //
        this.isShowStickyHeader = true

        this.$nextTick(() => {
          let el = document.getElementById('rfq-view-table')
          let el2 = document.getElementById('rfq-view-thead-wrapper')

          el2.scrollLeft = el.scrollLeft
        })
        // }
      } else {
        this.isShowStickyHeader = false
      }
    },
    handleResize () {
      this.documentHeight = window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight -
          document.getElementsByClassName('tabs-component')?.[0]?.clientHeight - 14

      this.documentWidth = window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth
      this.areaForOrderButton = window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth - document.getElementById('rfq-view-table')?.clientWidth

      if (!this.isOpenNonReturnalbleModal) {
        return
      }
      setTimeout(() => {
        _.forEach(this.supplierPrice.selectedSuppliers, (s) => {
          let signatureCanvas = this.$refs['signaturePad-' + s.id]?.[0]?.$el
          if (signatureCanvas) {
            let nonReturnableSignatureBlock = this.$refs['nonReturnableSignatureBlock-' + s.id]?.[0]
            if (nonReturnableSignatureBlock) {
              let width = nonReturnableSignatureBlock.offsetWidth
              signatureCanvas.width = width
              signatureCanvas.style.width = width + 'px'
              signatureCanvas.height = 100
              signatureCanvas.style.height = '100px'
            }
          }
          let signaturePad = this.$refs['signaturePad-' + s.id]?.[0]
          if (signaturePad) {
            signaturePad.inputPointsHandler()
          }
        })
      }, 100)
    },
    addedNewInsurer (v) {
      this.rfq.vehicle.insurer = v
      this.$store.commit('repairer/supplier/repaireInsurerAddNew', v)
    },
    filterRfqFiles (val) {
      // let data = _.filter(val, (f) => {
      //   return !(f.type === 'scheme')
      // })
      this.$set(this.rfq, 'files', val)
    },
    selectSchemeImage (isEdit = false) {
      this.selectedSchemeIndex = _.findIndex(this.computedSchemeImages, (i) => {
        return Number(i.id) === Number(this.schemeImage.id)
      })
      if (this.$refs['repairer-rfq-view-chat']) {
        this.$refs['repairer-rfq-view-chat'].openChat()
        this.$refs['repairer-rfq-view-chat'].scrollToTop()
      }
      if (isEdit) {
        this.$nextTick(() => {
          this.$refs['image-gallery']?.onClickEdit()
        })
      }
    },
    selectedFilesRows (rows) {
      this.isClosedPreviewImage = true
      if (rows?.[0]?.type === 'scheme') {
        this.schemeImage = rows[0]
      } else {
        this.schemeImage = null
      }
    },
    isSupplierExpired (supp) {
      let flag = false

      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        if (!s.isSentRequestToUpdate || s.isSentRequestToUpdate !== true) {
          if (s.quoteValidTill && this.isRFQPriced && Number(s.supplierId) === Number(supp.supplierId)) {
            let day1 = dayjs(s.quoteValidTill)
            let day2 = dayjs.utc()
            let diff = day1.diff(day2, 'day')
            // if (diff <= 0) {
            //   flag = true;
            // }
            if (day1 < day2) {
              flag = true
            }
          }
        }
      })

      return flag
    },
    upperCase (v, field) {
      v[field] = _.toUpper(v[field])
    },
    showNonReturnableModal1 (id) {
      this.supplierNonReturnableId = id
      // this.$refs['non-returnable-agreement'].show();

      _.forEach(this.supplierPrice.selectedSuppliers, (s) => {
        let isHasNonReturnableParts = _.some(s.selectedParts, (prtId) => {
          return s?.parts?.[prtId].nonReturnable
        })
        if (isHasNonReturnableParts) {
          this.nonReturnableSelectedParts[s.id] = []
          this.nonReturnableSettings[s.id] = {
            acceptanceOfTics: false,
            acceptedBy: '',
            positionHeld: ''
          }
          this.changedNonReturnableSettings()
          this.changedNonReturnableSelectedParts()
          this.$refs['non-returnable-example-' + s.id][0].show()
          this.isOpenNonReturnalbleModal = true
          this.$nextTick(() => {
            setTimeout(() => {
              let signatureCanvas = this.$refs['signaturePad-' + s.id]?.[0]?.$el
              // console.log(signatureCanvas, 'signatureCanvas')
              if (signatureCanvas) {
                let nonReturnableSignatureBlock = this.$refs['nonReturnableSignatureBlock-' + s.id]?.[0]
                // console.log(nonReturnableSignatureBlock, 'nonReturnableSignatureBlock')
                if (nonReturnableSignatureBlock) {
                  let width = nonReturnableSignatureBlock.offsetWidth
                  signatureCanvas.width = width
                  signatureCanvas.style.width = width + 'px'
                  signatureCanvas.height = 100
                  signatureCanvas.style.height = '100px'
                }
              }
            }, 100)
          })
        }
      })
    },
    showNonReturnableModal () {
      // this.$refs['non-returnable-agreement'].hide();

    },
    getNonReturnableParts (s) {
      let parts = []
      _.forEach(s.selectedParts, (prtId) => {
        if (s?.parts?.[prtId]?.nonReturnable) {
          let prt = _.find(this.supplierPrice.parts, (p) => {
            return Number(p.id) === Number(prtId)
          })
          if (prt) {
            parts.push([prt, s?.getPart(prtId)?.id])
          }
        }
      })
      return parts
    },
    checkSignature (ref) {
      if (this?.$refs?.[ref]?.[0]) {
        this.isEmptySignature = this.$refs[ref][0].isEmpty()
      }
    },
    clearSignature (ref) {
      let signaturePad = this?.$refs?.[ref]?.[0]
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    onPinnedImage (imgId) {
      Axios.post('/ir/repairer/rfq/' + this.rfq.id, {
        setPinImage: imgId
      })
          .then((r) => {
            if (r?.data?.update?.setPinImage) {
              if (imgId) {
                this.rfq.pinImage = {
                  id: imgId,
                }
              } else {
                this.rfq.pinImage = null
              }
              this.$toast.success('You have successfully pinned the image')
            } else {
              this.$toast.error('Pinning of the image has been failed')
            }
          })
          .catch(() => {
            this.$toast.error('Pinning of the image has been failed')
          })
    },
    removedFiles (ids) {
      this.rfq.images = _.filter(this.rfq.images, (img) => {
        return !ids.includes(img.id)
      })
    },
    openErrorModal () {
      this.$refs['quote-expired-warning'].show()
    },
    getRequestPeriod (supp) {
      return supp && supp.quoteValidityPeriod ? Number(supp.quoteValidityPeriod) : this.quoteOpenDaysForRequest
    },
    _toUpper (val) {
      return _.toUpper(val)
    },
    getCuttOff (time) {
      if (time && time.trim()) {
        let timeSplited = time.split(' || ')
        if (timeSplited.length !== 2) {
          return ''
        }
        return timeSplited[1] + ' || ' + timeSplited[0]
      }
      return ''
    },
    onHover () {
      if (this.isCanRequestRFQUpdate) {
        this.isShowRequestRFQUpdate = true
      }
      this.isOnHoverOptions = true
    },
    onMouseLeave () {
      if (this.isCanRequestRFQUpdate) {
        this.isShowRequestRFQUpdate = false
      }
      this.isOnHoverOptions = false
    },
    requestRfqUpdate (suppId) {
      if (suppId === -1 && !this.isCanRequestRfqUpdateToAll) {
        return
      }
      // if (Number(this.rfq.quoteOpenDays) < Number(quoteValidityPeriod)) {
      //   return
      // }

      this.$refs['repairer-supplier-view-options'].hide()
      NProgress.start()
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/request-rfq-update', {
        rfqId: this.rfq.id,
        suppliersIds: suppId !== -1 ? suppId : null,
      }).then(response => {
        if (response.data._status) {
          this.$toast.success('Request have sent successfully')
        }
      }).catch(error => {
        this.$toast.error('Error')
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
    },
    openPdfInNewTab () {
      //  window.open('https://www.toyotainsurancequote.com.au/product_disclosure_statement_pdf', '_blank').focus()
    },
    removePart (part) {
      let originalPartIndex = _.findIndex(this.rfq.parts, (prt) => {
        return Number(prt.id) === Number(part.id)
      })
      if (originalPartIndex !== -1) {
        this.rfq.parts.splice(originalPartIndex, 1)
      }
      this.deletePartsIds.push(part.id)
      this.clickSave()
    },
    savePart (part) {
      let originalPartIndex = _.findIndex(this.rfq.parts, (prt) => {
        return Number(prt.id) === Number(part.id)
      })
      if (originalPartIndex !== -1) {
        this.rfq.parts[originalPartIndex].number = part.number
        this.rfq.parts[originalPartIndex].qty = part.qty
        this.rfq.parts[originalPartIndex].originalQty = part.qty
        this.rfq.parts[originalPartIndex].name = part.name
      }
      this.clickSave()
    },
    getListPriceClicked () {
      if (!this.ableToSendListPrices) return
      NProgress.start()
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/send-list-prices', {
        rfqId: this.rfq.id
      }).then(response => {
        if (response.data?._status) {
          this.$toast.success('List pricing has been sent successfully to your quoting package')
        } else {
          this.$toast.error('Error sending your pricing. Error: ' + (response.data._error || response.data.error) + ' Contact Support')
        }
      }).catch(error => {
        this.$toast.error('Error sending your pricing. Error: ' + error.message + ' Contact Support')
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
    },
    deleteQuote () {
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/delete', {
        rfqId: this.rfq.id
      }).then(response => {
        this.$toast.success('Quote has been deleted')
        this.$store.dispatch('repairer/rfq/init', {}, { root: true })
        this.$store.commit('repairer/rfq/repairerRFQDelete', { id: this.rfq.id })
        if (this.isRFQReceived) {
          this.$router.push('/r/rfqs/#received')
        } else {
          this.$router.push('/r/rfqs/#cancelled')
        }
      }).catch(error => {
        this.$toast.error('Quote haven\'t been deleted')
        console.log(error)
      })
    },
    orderCreate: function (data, isNeedShowModals = true) {
      let isHasNonReturnableSupp = _.some(this.supplierPrice.selectedSuppliers, (s) => {
        return _.some(s.selectedParts, (prtId) => {
          return s?.parts?.[prtId]?.nonReturnable
        })
      })
      if (isHasNonReturnableSupp && isNeedShowModals) {
        this.showNonReturnableModal1()
        return
      }

      if (_.isEmpty(data)) {
        return
      }
      Axios.post('/ir/repairer/order/pre-create',
          {
            rfqId: this.rfq.id,
            partsToOrders: data
          }).then(response => {
        //Redirect
        //this.$toast.success("Order has been created");
        this.$router.push({
          name: 'RepairerRfqOrder',
          props: true,
          params: {
            rfq_id: this.rfq.id,
            selectedParts: this.supplierPrice.parts,
            rfq: this.rfq,
            isExtensionActive: this.isExtensionActive
          }
        })
      }).catch(error => {
        this.$toast.error('Order hasn\'t been created')
        console.log(error)
      })
    },
    changePositionByArrowKey: function (ref, event) {
      let el = this.$refs[ref]
      if (el) {
        el.focus()
      }
    },
    sortByPartName: function () {
      if (!this.sortByPartNameAsc) {
        this.sortByPartNameAsc = true
        this.sortByPartNameDesc = false
        this.sortByQtyAsc = false
        this.sortByQtyDesc = false
      } else {
        this.sortByPartNameDesc = true
        this.sortByPartNameAsc = false
        this.sortByQtyAsc = false
        this.sortByQtyDesc = false
      }
    },
    sortByQty: function () {

      if (!this.sortByQtyAsc) {
        this.sortByQtyAsc = true
        this.sortByQtyDesc = false
        this.sortByPartNameAsc = false
        this.sortByPartNameDesc = false
      } else {
        this.sortByQtyDesc = true
        this.sortByQtyAsc = false
        this.sortByPartNameAsc = false
        this.sortByPartNameDesc = false
      }
    },
    changedTab: function (obj) {
      this.activeTab = obj.tab.name
    },
    editVehicleDetails: function () {
      this.isEditVehicleDetails = true
    },
    moveToSubmitQuote () {
      this.$router.push('/r/rfq/add/received/' + this.rfq.id)
    },
    moveToPriced: function () {
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/move-to-priced', {
        rfqId: this.rfq.id
      }).then(response => {
        this.$toast.success('Quote has been priced')
        this.loadRFQ(this.rfq.id)
      }).catch(error => {
        this.$toast.error('Quote haven\'t been priced')
        console.log(error)
      })
    },
    cancelQuote: function () {
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/cancel', {
        cancelId: this.rfq.id
      }).then(response => {
        this.$toast.success('Quote has been cancelled')
        this.$store.dispatch('repairer/rfq/init', {}, { root: true })
        this.loadRFQ(this.rfq.id)
      }).catch(error => {
        this.$toast.error('Quote haven\'t been cancelled')
        console.log(error)
      })
    },
    reactivateQuote: function () {
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/reactivate', {
        reactivateId: this.rfq.id
      }).then(response => {
        this.$toast.success('Quote has been reactivated')
        this.loadRFQ(this.rfq.id)
      }).catch(error => {
        this.$toast.error('Quote haven\'t been reactivated')
        console.log(error)
      })
    },
    editPartList: function () {
      this.isEditPartList = true
    },
    cancelEdit: function () {
      this.isEditPartList = false
      this.isEditVehicleDetails = false
    },
    ifOfferChecked: function (partId, offer) {
      if (this.offers && offer && offer.id) {
        return _.findIndex(this.offers, { partId: partId, offerId: offer.id }) !== -1
      }
      return false
    },
    checkOfferForOrder: function (partId, offerId, supplierId, part, offer) {
      if (part) {
        let i = this.offers.findIndex(offer => offer.partId === partId)
      }
      const index = this.offers.findIndex(offer => offer.partId === partId)
      let selectedPart = {}
      if (Array.isArray(this.rfq.parts_to_suppliers[supplierId].parts)) {
        const partIndex = this.rfq.parts_to_suppliers[supplierId].parts.findIndex(part => part.partId === partId || part.id === partId)
        if (partIndex !== -1) {
          selectedPart = this.rfq.parts_to_suppliers[supplierId].parts[partIndex]
        } else {
          return
        }
      } else {
        selectedPart = this.rfq.parts_to_suppliers[supplierId].parts[partId]
      }

      if (index !== -1) {
        this.offers.splice(index, 1)
      }

      // don't select if user select offer with no price
      if (!selectedPart.price) {
        return
      }

      if (this.ifOfferChecked(partId, offerId)) {
        _.remove(this.offers, function (i) {
          return i.offerId == offerId
        })
        this.offers.push({ partId: partId, offerId: 0, supplierId: supplierId })
      } else {
        _.remove(this.offers, function (i) {
          return i.offerId == offerId
        })
        this.offers.push({ partId: partId, offerId: offerId, supplierId: supplierId })
      }
    },
    clickSave: function () {
      Axios.post('/ir/repairer/rfq/' + this.rfq.id, {
        vehicle: this.rfq.vehicle,
        cuttOff: this.getCuttOff(this.rfq.cuttOff),
        QPNumber: this.rfq.QPNumber,
        parts: this.rfq.parts,
        deletePartsIds: this.deletePartsIds,
        estimator: this.rfq.estimator,
      })
          .then(response => {
            this.$toast.success('The RFQ has been updated')
            this.cancelEdit()
            if (response.data.update && response.data.update.cuttOff && response.data.rfq && response.data.rfq.id == this.rfq.id) {
              this.rfq.cuttOff = response.data.rfq.cuttOff
              this.rfq.dateDue = response.data.rfq.dateDue
              this.rfq.dueCountdown = response.data.rfq.dueCountdown
            }
            this.deletePartsIds.splice(0, this.deletePartsIds.length)
          })
          .catch(error => {
            this.$toast.error('The RFQ haven\'t been updated')
            console.log(error)
          })
    },
    loadRFQ: function (id, isNeedUpdatePartsSelection) {
      this.getRFQById(id).then(response => {
        if (response?.data?.status && response?.data?.isMerged) {
          this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: response.data.mergedTo } })
          return
        }
        this.rfq = JSON.parse(JSON.stringify(response.data.rfq))

        if (!_.isEmpty(response.data.rfq.purchasePriceSelector) && (this.rfq.status === 'Priced' || this.rfq.status === 'Ordered') && !this.isRfqSentToSingleSupplier) {
          _.forEach(response.data.rfq.purchasePriceSelector.rules, (itm) => {
            let priceSet = _.find(this.priceSets, (set) => {
              return set.name === itm?.name && itm?.rule?.value === set.value && itm?.rule?.suppType === set.suppType && itm?.rule?.action === set.action
            })
            if (!priceSet) {
              this.priceSets.unshift({
                name: itm?.name,
                label: itm?.name,
                action: itm?.rule?.action,
                value: itm?.rule?.value,
                suppType: itm?.rule?.suppType
              },)
            }

            if (this.rfq.status === 'Ordered') {
              let priceSet = _.find(this.orderedPriceSets, (set) => {
                return set.name === itm?.name && itm?.rule?.value === set.value && itm?.rule?.suppType === set.suppType && itm?.rule?.action === set.action
              })
              if (!priceSet) {
                this.orderedPriceSets.unshift({
                  name: itm?.name,
                  label: itm?.name,
                  action: itm?.rule?.action,
                  value: itm?.rule?.value,
                  suppType: itm?.rule?.suppType
                },)
              }
            }

          })
          if (!_.find(this.priceSets, (itm) => { return itm.name == this.rfq.vehicle.insurer + ' - M/UP CHK'})) {
            this.priceSets.unshift({
              name: this.rfq.vehicle.insurer + ' - M/UP CHK',
              label: this.rfq.vehicle.insurer + ' - M/UP CHK',
              isAllowscsChk: true
            },)
          }

          if (!_.find(this.orderedPriceSets, (itm) => { return itm.name == this.rfq.vehicle.insurer + ' - M/UP CHK'})) {
            this.orderedPriceSets.unshift({
              name: this.rfq.vehicle.insurer + ' - M/UP CHK',
              label: this.rfq.vehicle.insurer + ' - M/UP CHK',
              isAllowscsChk: true
            },)
          }
        }

        this.filterRfqFiles(this.rfq.files)

        if (!this.isClosedPreviewImage) {
          let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
            return f.type === 'scheme'
          })
          if (indexOfSchemeImg !== -1) {
            this.schemeImage = this.rfq.files[indexOfSchemeImg]
            this.$nextTick(() => {
              this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
            })
          }
        }

        this.rfq.estimator = (!_.isEmpty(this.rfq.estimator)) ? String(this.rfq.estimator) : ''
        if (this.rfq.preOrders) {
          this.$router.push({
            name: 'RepairerRfqOrder',
            props: true,
            params: {
              rfq_id: this.rfq.id,
              isExtensionActive: this.isExtensionActive
            }
          })
          return
        }

        let rfqCopy = _.cloneDeep(this.rfq)

        let parts = []
        _.forEach(rfqCopy.parentRfqParts, (prt) => {
          prt.isParentPart = true
          parts.push(prt)
        })
        _.forEach(rfqCopy.additionalRfqParts, (prt) => {
          prt.isAdditional = true
          parts.push(prt)
        })
        _.forEach(rfqCopy.parts, (prt) => {
          parts.push(prt)
        })

        _.forIn(rfqCopy.parts_to_suppliers, (supp) => {

          _.forIn(rfqCopy.parentRfqPartsToSuppliers, (s) => {
            if (Number(s.supplierId) !== Number(supp.supplierId)) {
              return
            }
            let parentSupp = s

            supp.parentRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
            supp.isParent = true
            if (_.isEmpty(supp.parentIds)) {
              supp.parentIds = [parentSupp.id]
            } else {
              supp.parentIds.push(parentSupp.id)
            }

            let parts = []
            _.forEach(parentSupp.parts, (prt) => {
              prt.isParentPart = true
              prt.parentRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
              prt.parentSuppId = parentSupp.id
              parts.push(prt)
            })
            _.forEach(supp.parts, (prt) => {
              parts.push(prt)
            })
            supp.parts = parts

            let offers = []
            _.forEach(parentSupp.offers, (itm) => {
              itm.isParentPart = true
              offers.push(itm)
            })
            _.forEach(supp.offers, (itm) => {
              offers.push(itm)
            })
            supp.offers = offers

            let rrp = {}
            _.forIn(parentSupp.rrp, (itm, key) => {
              rrp[key] = itm
            })
            _.forIn(supp.rrp, (itm, key) => {
              rrp[key] = itm
            })
            supp.rrp = rrp

          })

          _.forIn(rfqCopy.additionalRfqPartsToSuppliers, (s) => {
            if (!(Number(s.supplierId) === Number(supp.supplierId) && s.type == supp.type)) {
              return
            }
            let parentSupp = s

            supp.additionalRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
            supp.isAdditional = true
            if (_.isEmpty(supp.additionalIds)) {
              supp.additionalIds = [parentSupp.id]
            } else {
              supp.additionalIds.push(parentSupp.id)
            }

            let parts = []
            _.forEach(parentSupp.parts, (prt) => {
              prt.isAdditional = true
              prt.additionalSuppId = parentSupp.id
              prt.additionalRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
              parts.push(prt)
            })
            _.forEach(supp.parts, (prt) => {
              parts.push(prt)
            })
            supp.parts = parts

            let offers = []
            _.forEach(parentSupp.offers, (itm) => {
              itm.isAdditional = true
              offers.push(itm)
            })
            _.forEach(supp.offers, (itm) => {
              offers.push(itm)
            })
            supp.offers = offers

            let rrp = {}
            _.forIn(parentSupp.rrp, (itm, key) => {
              rrp[key] = itm
            })
            _.forIn(supp.rrp, (itm, key) => {
              rrp[key] = itm
            })
            supp.rrp = rrp

          })
        })
        let suppliers = rfqCopy.parts_to_suppliers

        let manualParts = []
        _.forEach(rfqCopy.parentRfqManualParts, (prt) => {
          prt.isParentPart = true
          manualParts.push(prt)
        })
        _.forEach(rfqCopy.additionalRfqManualParts, (prt) => {
          prt.isAdditional = true
          manualParts.push(prt)
        })
        _.forEach(rfqCopy.manualParts, (prt) => {
          manualParts.push(prt)
        })

        let partsOrdered = []
        _.forEach(rfqCopy.parentRfqPartsOrdered, (prt) => {
          prt.isParentPart = true
          partsOrdered.push(prt)
        })
        _.forEach(rfqCopy.additionalRfqPartsOrdered, (prt) => {
          prt.isAdditional = true
          partsOrdered.push(prt)
        })
        _.forEach(rfqCopy.partsOrdered, (prt) => {
          partsOrdered.push(prt)
        })
        let orders = {}

        _.forIn(rfqCopy.orders, (order, orderId) => {
          orders[orderId] = order
        })
        _.forIn(rfqCopy.parentOrders, (order, orderId) => {
          order.isParent = true
          orders[orderId] = order
        })
        _.forIn(rfqCopy.additionalOrders, (order, orderId) => {
          order.isAdditional = true
          orders[orderId] = order
        })

        //_.forEach(parts, part => { part.isManual = false });
        //_.forEach(manualParts, part => { part.isManual = true });
        if (!_.isEmpty(manualParts)) parts = parts.concat(manualParts)
        this.supplierPrice = new SupplierPrice(suppliers, parts, orders, partsOrdered, this.rfq.status)
        this.supplierPrice.orderParts()
        this.$nextTick(() => {
          console.log('loadRfq', this.isRFQPriced, this.isRfqSentToMultipleSupplier)
          //console.log('!!!!!!',this.rfq.selectedParts)
          //console.log('!!!!!!222',this.isAlternate)
          if (this.isRFQPriced && !_.isEmpty(this.rfq.selectedParts)) {
            this.$nextTick(() => {
              this.supplierPrice.selectPartsByIds(this.rfq.selectedParts)
            })
          } else if (this.isRFQPriced && !this.isAlternate) {
            console.log('!!!!!!AutoSelectedParts')
            if (this.rfq?.parts_to_suppliers && Object.keys(this.rfq.parts_to_suppliers).length > 0) {
              this.supplierPrice.selectAllPartsFrom(Object.keys(this.rfq.parts_to_suppliers)[0])
              this.saveSelectedParts(this.supplierPrice.getSelectedPartsIds())
            }
          }

          if (response.data.chatMessages) {
            this.chatMessages = response.data.chatMessages
            if (this.unviewedMessagesIds.length > 0) {
              this.markMessagesAsViewed(this.unviewedMessagesIds)
            }
          }

        })
        setTimeout(() => {
          this.selectedSuppliers = _.cloneDeep(this.supplierPrice.selectedSuppliers)
        }, 100)

        if (!this.isShowDiagram && this.$route?.params?.shemeId && (this.isRFQSubmitted || this.isRFQPriced || this.isRFQOrdered || this.isRFQCancelled)) {
          this.isShowDiagram = true
          let isHasFile = _.some(this.rfq.files, (f) => {
            return Number(f.id) == Number(this.$route.params.shemeId)
          })
          if (isHasFile) {
            setTimeout(() => {
              this.$refs['rfq-view-tabs'].selectTab('#files')
              this.selectSchemeImage(false)
            }, 0)
          }
        }
      }).catch(error => {
        console.log(error)
        this.$toast.error('Page not found')
        this.$router.push('/r')
      })
    },
    vehicleDetailsTabChange () {
      this.isHiddenOptions = false
      this.classTabContent = 'tab-content-table'
    },
    partListTabChange () {
      this.isHiddenOptions = true
      this.classTabContent = 'tab-content-table'
    },
    imagesTabChange () {
      this.isHiddenOptions = false
      this.classTabContent = 'tab-content-table'
    },
    filesTabChange () {
      this.cardSaveElement = 'Files'
      this.isHiddenOptions = false
      this.classTabContent = 'tab-content-table'
    },
    saveSelectedParts (data) {
      this.isSavingSelectedParts = true
      this.savedSelectedParts = data
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/set-selected-parts', {
        timestamp: Date.now(),
        selectedParts: data,
      })
          .then(response => {
          })
          .catch(error => {
          })
          .finally(() => {
            this.isSavingSelectedParts = false
            this.savedSelectedParts = []
          })
    },
    unSelectSupplier () {
      this.selectedPriceSet = ''
      this.supplierPrice.selectedPriceSet = ''
      this.saveSelectedParts(this.supplierPrice.getSelectedPartsIds())
    },
    priceClicked (supplierId, partId, offerId, part, offer, supplierType) {
      if (_.isEmpty(this.lastSelectedPriceSet)) {
        this.lastSelectedPriceSet = this.selectedPriceSet
        this.lastSelectedParts = _.cloneDeep(this.selectedParts)
      }
      if (this.selectedPriceSet !== 'selection') {
        // this.selectedPriceSet = 'selection';
      }
      if (_.isEqual(_.sortBy(this.selectedParts, ['partId']), _.sortBy(_.sortBy(this.partsSelectedByBestValue, ['partId'])))) {
        this.selectedPriceSet = 'singleSupplier'
      } else if (_.isEqual(_.sortBy(this.selectedParts, ['partId']), _.sortBy(_.sortBy(this.partsSelectedByInStock, ['partId'])))) {
        this.selectedPriceSet = 'partsInStock'
      } else if (_.isEqual(_.sortBy(this.selectedParts, ['partId']), _.sortBy(_.sortBy(this.partsSelectedByAllSupps, ['partId'])))) {
        this.selectedPriceSet = 'allSupplier'
      } else {
        this.selectedPriceSet = ''
      }

      if (this.lastSelectedPriceSet && _.find(this.priceSets, (set) => {return set.name === this.lastSelectedPriceSet}) && this.lastSelectedParts.length - this.selectedParts.length == 1) {
        let oemGenSupp = null
        for (let suppId in this.supplierPrice.suppliers) {
          let sup = this.supplierPrice.suppliers[suppId]
          if (sup && (sup.type == 'OEM-GEN')) {
            oemGenSupp = sup
          }
        }
        if (_.find(this.priceSets, (set) => {return set.name === this.lastSelectedPriceSet}).suppType === 'OEM-GEN' && !_.isEmpty(oemGenSupp)) {
          let b = _.some(this.selectedParts, (p) => {
            return !_.find(this.lastSelectedParts, (itm) => {
              return Number(p.partId) == Number(itm.partId) && Number(p.suppId) === Number(itm.suppId)
            }) && !_.includes(oemGenSupp.selectedParts, p.partId)
          })
          if (!b) {
            this.selectedPriceSet = this.lastSelectedPriceSet
            let set = _.find(this.priceSets, (s) => {
              return s.name === this.lastSelectedPriceSet
            })
            if (set) {
              this.supplierPrice.getPricesByRule(set)
            }
          }
        }
      }

      this.supplierPrice.selectedPriceSet = this.selectedPriceSet
      if (partId && offerId) this.checkOfferForOrder(partId, offerId, supplierId, part, offer)

      setTimeout(() => {
        this.selectedSuppliers = _.cloneDeep(this.supplierPrice.selectedSuppliers)
      }, 100)
      this.saveSelectedParts(this.supplierPrice.getSelectedPartsIds())
    },
    handlePriceSetSelectorPressed (priceSet) {
      if (priceSet == this.selectedPriceSet && priceSet == this.rfq.vehicle.insurer + ' - M/UP CHK') {
        this.supplierPrice.isAllowscsChk = false
        this.selectedPriceSet = ''
        this.supplierPrice.selectedPriceSet = ''
        if (this.$refs[this.rfq.vehicle.insurer + ' - M/UP CHK']?.[0]) {
          this.$refs[this.rfq.vehicle.insurer + ' - M/UP CHK'][0].blur()
        }
        return
      }
      this.lastSelectedPriceSet = this.selectedPriceSet
      this.lastSelectedParts = _.cloneDeep(this.selectedParts)
      console.log('priceSet', priceSet, this.supplierPrice)
      this.selectedPriceSet = priceSet
      if (!_.isEmpty(this.supplierPrice)) {
        if (priceSet !== 'selection' && priceSet !== this.rfq.vehicle.insurer + ' - M/UP CHK') {
          this.supplierPrice.unselectAll()
        }
        if (priceSet !== 'singleSupplier' && priceSet !== 'selection' && priceSet !== 'allSupplier' && priceSet !== 'partsInStock') {
          let set = _.find(this.priceSets, (s) => {
            return s.name === priceSet
          })
          if (set) {
            this.supplierPrice.getPricesByRule(set)
          }
        } else if (priceSet === 'singleSupplier') {
          this.supplierPrice.getBestValueSingleSupplier()
          this.partsSelectedByBestValue = []
          _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
            _.forEach(s.selectedParts, (id) => {
              this.partsSelectedByBestValue.push({
                partId: id,
                suppId: s.id,
              })
            })
          })
        } else if (priceSet === 'allSupplier') {
          this.supplierPrice.getBestValueAllSupplier()
          this.partsSelectedByAllSupps = []
          _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
            _.forEach(s.selectedParts, (id) => {
              this.partsSelectedByAllSupps.push({
                partId: id,
                suppId: s.id,
              })
            })
          })
        } else if (priceSet === 'partsInStock') {
          this.supplierPrice.getBestValuePartInStock()
          this.partsSelectedByInStock = []
          _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
            _.forEach(s.selectedParts, (id) => {
              this.partsSelectedByInStock.push({
                partId: id,
                suppId: s.id,
              })
            })
          })
        } else if (priceSet === 'initAllSelection' && this.getSingleSupplier) {
          this.supplierPrice.selectAllPartsFrom(this.getSingleSupplier.id)
        } else if (priceSet === 'selection') {
          if (this.isQuoteExpired) {
            this.$refs['quote-expired-warning'].show()
          } else {
            this.orderCreate(this.supplierPrice.getSelectedPartsIds())
          }
        }
      }
      this.supplierPrice.selectedPriceSet = this.selectedPriceSet
      this.saveSelectedParts(this.supplierPrice.getSelectedPartsIds())
    },
    transferListPrice () {
      return //#201.4
      // Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/send-list-prices', {
      //   rfqId: this.rfq.id
      // })
      //     .then(response => {
      //       if (!response || !response.data || !response.data._status) {
      //         this.$toast.error('Could not send List Price to quoting package')
      //         console.log(response)
      //         return
      //       }
      //       this.$toast.success('List Price sent to your quoting package')
      //     })
      //     .catch(error => {
      //       this.$toast.error('Could not send List Price to quoting package')
      //       console.log(error)
      //     })

    },
    sendToQuotingPackage (isWithBuyPrices = false) {

      let partsIds = []

      for (let suppId in this.supplierPrice.suppliers) {
        let sup = this.supplierPrice.suppliers[suppId]
        if (sup && sup.selectedParts.length > 0) {
          partsIds = partsIds.concat(sup.selectedParts)
        }
      }

      // if (this.isRFQPriced) {
      let actionName = isWithBuyPrices ? 'back List & Buy Price' : 'back List'
      Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/send-list-prices', {
        rfqId: this.rfq.id,
        partsIds: partsIds,
        withBuyPrices: isWithBuyPrices,
      })
          .then(response => {
            if (!response || !response.data || !response.data._status) {
              this.$toast.error('Failed to send ' + actionName + ' to Repair-Shop')
              console.log(response)
              return
            }
            this.$toast.success('Priced Quote sent ' + actionName + ' to your Repair-Shop')
          })
          .catch(error => {
            this.$toast.error('Failed to send ' + actionName + ' to Repair-Shop')
            console.log(error)
          })
      // }

      // if (this.isRFQOrdered) {
      //
      //   Axios.post('/ir/repairer/rfq/' + this.rfq.id + '/send-ordered-prices', {
      //     rfqId: this.rfq.id
      //   })
      //     .then(response => {
      //       if (!response || !response.data || !response.data._status) {
      //         this.$toast.error('Failed to send Order Pricing to Repair-Shop')
      //         console.log(response)
      //         return
      //       }
      //       this.$toast.success('Order Pricing sent to your Repair-Shop')
      //     })
      //     .catch(error => {
      //       this.$toast.error('Failed to send Order Pricing to Repair-Shop')
      //       console.log(error)
      //     })
      //
      // }
    },
    importExtQpsPartsIds (parts) {
      Axios.post(`/ir/repairer/rfq/${this.rfq.id}/update-ext-qps-parts-ids`, { parts })
          .then(response => {
            if (response.data?._status) {
              this.$toast.success('Sync data finished!')
              window.postMessage({
                type: 'partsbridge-IBODYSHOP_NEW_PARTS_SYNC_COMPLETED'
              })
            }
          })
          .catch(e => console.error(e))
    },
    exportRfqToPartsbridge () {
      const rfq = {
        id: this.rfq.id,
        qpNumber: this.rfq.QPNumber,
        number: this.rfq.number,
        extQps: this.rfq.extQps ? { ...this.rfq.extQps } : null,
        vehicle: { ...this.rfq.vehicle },
      }
      const updateParts = {}
      const partsByid = {}
      const addParts = []

      for (let p of this.rfq.parts) {
        let part = {
          qpsId: p.qpsPart?.id || null,
          psPartId: p.id,
          psPartToSupplierId: null,
          description: p.nameBySupplier || p.name,
          rrp: p.rrpBySupplier || p.rrp,
          price: null,
          qty: p.qty,
          partNumber: p.numberBySupplier || p.number,
        }
        partsByid[p.id] = part
        if (p.qpsPart) {
          updateParts[p.qpsPart.id] = part
        } else {
          addParts.push(part)
        }
      }

      for (let [rfqToSupplierId, supplier] of Object.entries(this.rfq.parts_to_suppliers)) {
        if (supplier.type != 'OEM-GEN' && supplier.type != 'OEM-PAR') continue
        for (let partToSupplier of supplier.parts) {
          if (partToSupplier.id === null) {
            continue
          }
          let name,
              number,
              price,
              rrp,
              qty

          if (partToSupplier.type === 'delivery') {
            name = 'Delivery'
            number = null
            price = null
            rrp = partToSupplier.price
            qty = 1
          }
          if (partToSupplier.type === 'manual') {
            name = partToSupplier.name
            number = partToSupplier.number
            price = partToSupplier.price
            rrp = partToSupplier.rrp
            qty = partToSupplier.qty
          }
          if (partToSupplier.type === 'part') {
            let part = partsByid[partToSupplier.partId]
            name = part.description
            number = partToSupplier.number || part.partNumber
            price = partToSupplier.price === null ? part.price : partToSupplier.price
            rrp = partToSupplier.rrp === null ? part.rrp : partToSupplier.rrp
            qty = part.qty
          }
          if (partToSupplier.partId && partToSupplier.partId != 0) {
            let part = partsByid[partToSupplier.partId]
            part.description = name
            part.partNumber = number
            part.price = price
            part.rrp = rrp
            part.qty = qty
          } else if (partToSupplier.qpsPart) {
            let part = {
              qpsId: partToSupplier.qpsPart.id,
              psPartId: null,
              psPartToSupplierId: partToSupplier.id,
            }
            part.description = name
            part.partNumber = number
            part.price = price
            part.rrp = rrp
            part.qty = qty
            updateParts[partToSupplier.qpsPart.id] = part
          } else {
            let part = {
              qpsId: null,
              psPartId: null,
              psPartToSupplierId: partToSupplier.id,
              description: name,
              rrp: rrp,
              price: price,
              qty: qty,
              partNumber: number,
            }
            addParts.push(part)
          }
        }
      }

      const data = {
        rfq,
        updateParts,
        addParts,
      }

      console.log('[PARTSBRIDGE]', '[RFQ DATA EXPORT]', data)
      window.postMessage({ type: 'RFQ_DATA_EXPORT', data })
    },
    sendExtQpsRfqData (is_init = false) {
      console.log('CONNECTED_ESTIMATE_DATA', this.rfq.extQps)
      let type = 'CONNECTED_ESTIMATE_DATA'
      if (is_init) {
        type += '_INIT'
      }
      window.postMessage({
        type,
        data: this.rfq.extQps || null
      })
    },
    onChatMessage (data) {
      if (data.type === 'newMessage') {
        this.chatMessages.push(data)
        if (data.messageId && data.companyId != this.companyInfo.id) {
          this.markMessagesAsViewed([data.messageId])
        }
      }
      if (data.type === 'supplierIsOnline') {
        this.chatSupplierIsOnline = true
        this.supplierLeavedTheChat()
      }
      if (data.type === 'typingInChat' && data.id !== this.currentUser.id) {
        this.chatTypers[data.id] = {
          name: data.name,
          microtime: Date.now(),
        }
      }
    },
    onChatInputTyping () {
      this._chat.publish({
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      })
    },
    sendChatMessage (text) {
      if (!text || !text.trim()) {
        return new Promise((resolve) => {
          resolve(false)
        })
      }
      return Axios.post('/centrifuge/repairer/send-message-to-rfq', {
        rfqId: this.rfq.id,
        message: text.trim(),
      })
    },
    onChatMessageSubmit ({ message, resolve }) {
      if (this.unviewedMessagesIds.length > 0) {
        this.markMessagesAsViewed(this.unviewedMessagesIds)
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
      this.sendChatMessage(message).then(res => {
        resolve(res.data?._status)
      }).catch(() => {
        resolve(false)
      })
    },
    markMessagesAsViewed (messageIds = []) {
      return Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        messageIds
      })
    }
  },
  created: function () {
    this.loadRFQ(this.$route.params.rfq_id, true)
    this.$eventBus.$on('message-RFQ_DATA_EXPORT_RUN', this.exportRfqToPartsbridge)
    this.$eventBus.$on('message-NEW_PARTS_IDS_EXPORT', this.importExtQpsPartsIds)
    this.$eventBus.$on('message-GET_CONNECTED_ESTIMATE_DATA', this.sendExtQpsRfqData)
    this.$eventBus.$on('message-IS-EXTENSION-ACTIVE', this.setExtensionActive);
    (async () => {
      this._chat = await subscribeToRfqChannel(this.$route.params.rfq_id, false, {
        onPublication: this.onChatMessage,
        onSubscribed: () => {
          this.onlineStatusIntervalId = setInterval(() => {
            this._chat.publish({ type: 'repairerIsOnline' })
          }, 2000)
        },
        onUnsubscribed: () => {
          if (this.onlineStatusIntervalId !== null) {
            clearInterval(this.onlineStatusIntervalId)
          }
        },
      })
    })()
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.supplierLeavedTheChat = _.debounce(function () {
      this.chatSupplierIsOnline = false
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.timestampInterval)
    this.$eventBus.$off('message-RFQ_DATA_EXPORT_RUN', this.exportRfqToPartsbridge)
    this.$eventBus.$off('message-NEW_PARTS_IDS_EXPORT', this.importExtQpsPartsIds)
    this.$eventBus.$off('message-GET_CONNECTED_ESTIMATE_DATA', this.sendExtQpsRfqData)
    this.$eventBus.$off('message-IS-EXTENSION-ACTIVE', this.setExtensionActive)
    this.$store.commit('repairer/rfq/repairerCurrentRFQUpdate', null)
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.onScroll)
    if (this._chat) {
      console.log('UNSUBSCRIBING!', this._chat)
      this._chat.unsubscribe()
      console.log('UNSUBSCRIBED!')
    }
    clearInterval(this.interval)
  },
  watch: {
    currentRFQ: {
      handler: function (val) {
        console.log('currentRFQ', val)
        if (!_.isEmpty(val) && Number(this.rfq.id) === Number(val.id)) {
          if (val?.status && val?.isMerged) {
            this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: val.mergedTo } })
            return
          }
          if (this.rfq.status === 'Submitted' && val.status === 'Ordered') {
            this.loadRFQ(this.$route.params.rfq_id)
            return
          }

          let isNeedSelectParts = this.rfq.status === 'Submitted' && val.status === 'Priced'

          let rfq = JSON.parse(JSON.stringify(val))

          this.$set(this.rfq, 'dueCountdown', rfq.dueCountdown)
          this.$set(this.rfq, 'quotePricingProgress', rfq.quotePricingProgress)
          this.$set(this.rfq, 'status', rfq.status)
          // this.$set(this.rfq, 'files', rfq.files)
          this.filterRfqFiles(rfq.files)
          this.$set(this.rfq, 'images', rfq.images)
          this.$set(this.rfq, 'parts', rfq.parts)
          let newPartsToSupps = rfq.parts_to_suppliers
          for (let suppId in newPartsToSupps) {
            if (newPartsToSupps[suppId]?.offers) {
              let oldSuppIndex = _.findIndex(this.supplierPrice.orderedSuppliers, (s) => { return Number(s.id) === Number(suppId)})
              if (oldSuppIndex !== -1 && this.supplierPrice.orderedSuppliers[oldSuppIndex]?.offers) {
                _.forEach(newPartsToSupps[suppId]?.offers, (offer) => {
                  let oldOffer = _.find(this.supplierPrice.orderedSuppliers[oldSuppIndex].offers, (o) => {
                    return Number(o.id) === Number(offer.id)
                  })
                  if (oldOffer && !_.isEmpty(oldOffer.purchasePriceFigures) && !_.isEmpty(offer.purchasePriceFigures)) {
                    offer.purchasePriceFigures.editMode = oldOffer.purchasePriceFigures.editMode
                  }
                })
              }
            }
          }
          this.$set(this.rfq, 'parts_to_suppliers', newPartsToSupps)
          this.$set(this.rfq, 'orders', rfq.orders)
          this.$set(this.rfq, 'manualParts', rfq.manualParts)
          this.$set(this.rfq, 'isFromRepairshop', rfq.isFromRepairshop)
          this.$set(this.rfq, 'isAskForPaintCode', rfq.isAskForPaintCode)
          this.$set(this.rfq, 'paintCodeForRepair', rfq.paintCodeForRepair)
          this.$set(this.rfq, 'statusId', rfq.statusId)
          this.$set(this.rfq, 'purchasePriceSelector', rfq.purchasePriceSelector)

          this.$set(this.rfq, 'additionalRfqParts', rfq.additionalRfqParts)
          this.$set(this.rfq, 'additionalRfqPartsToSuppliers', rfq.additionalRfqPartsToSuppliers)
          this.$set(this.rfq, 'additionalRfqManualParts', rfq.additionalRfqManualParts)
          this.$set(this.rfq, 'additionalRfqPartsOrdered', rfq.additionalRfqPartsOrdered)
          this.$set(this.rfq, 'additionalOrders', rfq.additionalOrders)

          this.$set(this.rfq, 'parentRfqParts', rfq.parentRfqParts)
          this.$set(this.rfq, 'parentRfqPartsToSuppliers', rfq.parentRfqPartsToSuppliers)
          this.$set(this.rfq, 'parentRfqManualParts', rfq.parentRfqManualParts)
          this.$set(this.rfq, 'parentRfqPartsOrdered', rfq.parentRfqPartsOrdered)
          this.$set(this.rfq, 'parentOrders', rfq.parentOrders)
          this.$set(this.rfq, 'secondRequestBestPrice', rfq.secondRequestBestPrice)
          this.$set(this.rfq, 'secondRequestBestPriceTimeEnd', rfq.secondRequestBestPriceTimeEnd)
          this.$set(this.rfq, 'thirdRequestBestPrice', rfq.thirdRequestBestPrice)
          this.$set(this.rfq, 'thirdRequestBestPriceTimeEnd', rfq.thirdRequestBestPriceTimeEnd)

          if (!this.isClaimNumberFieldFocused) {
            this.$set(this.rfq.vehicle, 'claimNumber', rfq.vehicle.claimNumber)
          }

          if (!_.isEqual(this.rfq.pinImage, rfq.pinImage)) {
            this.$set(this.rfq, 'pinImage', rfq.pinImage)
          }

          if (!_.isEqual(this.rfq.properties, rfq.properties)) {
            this.$set(this.rfq, 'properties', rfq.properties)
          }

          if (!_.isEmpty(rfq.purchasePriceSelector) && (rfq.status === 'Priced' || rfq.status === 'Ordered') && !this.isRfqSentToSingleSupplier) {
            _.forEach(rfq.purchasePriceSelector.rules, (itm) => {
              let priceSet = _.find(this.priceSets, (set) => {
                return set.name === itm?.name && itm?.rule?.value === set.value && itm?.rule?.suppType === set.suppType && itm?.rule?.action === set.action
              })
              if (!priceSet) {
                this.priceSets.unshift({
                  name: itm?.name,
                  label: itm?.name,
                  action: itm?.rule?.action,
                  value: itm?.rule?.value,
                  suppType: itm?.rule?.suppType
                },)
              }

              if (this.rfq.status === 'Ordered') {
                let priceSet = _.find(this.orderedPriceSets, (set) => {
                  return set.name === itm?.name && itm?.rule?.value === set.value && itm?.rule?.suppType === set.suppType && itm?.rule?.action === set.action
                })
                if (!priceSet) {
                  this.orderedPriceSets.unshift({
                    name: itm?.name,
                    label: itm?.name,
                    action: itm?.rule?.action,
                    value: itm?.rule?.value,
                    suppType: itm?.rule?.suppType
                  },)
                }
              }

            })
            if (!_.find(this.priceSets, (itm) => { return itm.name == this.rfq.vehicle.insurer + ' - M/UP CHK'})) {
              this.priceSets.unshift({
                name: this.rfq.vehicle.insurer + ' - M/UP CHK',
                label: this.rfq.vehicle.insurer + ' - M/UP CHK',
                isAllowscsChk: true
              },)
            }

            if (!_.find(this.orderedPriceSets, (itm) => { return itm.name == this.rfq.vehicle.insurer + ' - M/UP CHK'})) {
              this.orderedPriceSets.unshift({
                name: this.rfq.vehicle.insurer + ' - M/UP CHK',
                label: this.rfq.vehicle.insurer + ' - M/UP CHK',
                isAllowscsChk: true
              },)
            }
          }

          if (this.schemeImage) {
            let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
              return Number(f.id) === Number(this.schemeImage.id)
            })
            if (indexOfSchemeImg !== -1) {
              this.schemeImage = this.rfq.files[indexOfSchemeImg]
              this.$nextTick(() => {
                this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
              })
            }
          }

          if (!this.isClosedPreviewImage && !this.schemeImage) {
            let indexOfSchemeImg = _.findIndex(this.rfq.files, (f) => {
              return f.type === 'scheme'
            })
            if (indexOfSchemeImg !== -1) {
              this.schemeImage = this.rfq.files[indexOfSchemeImg]
              this.$nextTick(() => {
                this.$refs['files-upload']?.selectRow(indexOfSchemeImg)
              })
            }
          }

          let rfqCopy = _.cloneDeep(this.rfq)

          let parts = []
          _.forEach(rfqCopy.parentRfqParts, (prt) => {
            prt.isParentPart = true
            parts.push(prt)
          })
          _.forEach(rfqCopy.additionalRfqParts, (prt) => {
            prt.isAdditional = true
            parts.push(prt)
          })
          _.forEach(rfqCopy.parts, (prt) => {
            parts.push(prt)
          })

          _.forIn(rfqCopy.parts_to_suppliers, (supp) => {

            _.forIn(rfqCopy.parentRfqPartsToSuppliers, (s) => {
              if (Number(s.supplierId) !== Number(supp.supplierId)) {
                return
              }
              let parentSupp = s

              supp.parentRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
              supp.isParent = true
              if (_.isEmpty(supp.parentIds)) {
                supp.parentIds = [parentSupp.id]
              } else {
                supp.parentIds.push(parentSupp.id)
              }

              let parts = []
              _.forEach(parentSupp.parts, (prt) => {
                prt.isParentPart = true
                prt.parentRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
                prt.parentSuppId = parentSupp.id
                parts.push(prt)
              })
              _.forEach(supp.parts, (prt) => {
                parts.push(prt)
              })
              supp.parts = parts

              let offers = []
              _.forEach(parentSupp.offers, (itm) => {
                itm.isParentPart = true
                offers.push(itm)
              })
              _.forEach(supp.offers, (itm) => {
                offers.push(itm)
              })
              supp.offers = offers

              let rrp = {}
              _.forIn(parentSupp.rrp, (itm, key) => {
                rrp[key] = itm
              })
              _.forIn(supp.rrp, (itm, key) => {
                rrp[key] = itm
              })
              supp.rrp = rrp

            })

            _.forIn(rfqCopy.additionalRfqPartsToSuppliers, (s) => {
              if (!(Number(s.supplierId) === Number(supp.supplierId) && s.type == supp.type)) {
                return
              }
              let parentSupp = s

              supp.additionalRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
              if (_.isEmpty(supp.additionalIds)) {
                supp.additionalIds = [parentSupp.id]
              } else {
                supp.additionalIds.push(parentSupp.id)
              }
              supp.isAdditional = true

              let parts = []
              _.forEach(parentSupp.parts, (prt) => {
                prt.isAdditional = true
                prt.additionalSuppId = parentSupp.id
                prt.additionalRfqToSupplierStatus = parentSupp.rfqToSupplierStatus
                parts.push(prt)
              })
              _.forEach(supp.parts, (prt) => {
                parts.push(prt)
              })
              supp.parts = parts

              let offers = []
              _.forEach(parentSupp.offers, (itm) => {
                itm.isAdditional = true
                offers.push(itm)
              })
              _.forEach(supp.offers, (itm) => {
                offers.push(itm)
              })
              supp.offers = offers

              let rrp = {}
              _.forIn(parentSupp.rrp, (itm, key) => {
                rrp[key] = itm
              })
              _.forIn(supp.rrp, (itm, key) => {
                rrp[key] = itm
              })
              supp.rrp = rrp

            })
          })
          let suppliers = rfqCopy.parts_to_suppliers

          let manualParts = []
          _.forEach(rfqCopy.parentRfqManualParts, (prt) => {
            prt.isParentPart = true
            manualParts.push(prt)
          })
          _.forEach(rfqCopy.additionalRfqManualParts, (prt) => {
            prt.isAdditional = true
            manualParts.push(prt)
          })
          _.forEach(rfqCopy.manualParts, (prt) => {
            manualParts.push(prt)
          })

          let partsOrdered = []
          _.forEach(rfqCopy.parentRfqPartsOrdered, (prt) => {
            prt.isParentPart = true
            partsOrdered.push(prt)
          })
          _.forEach(rfqCopy.additionalRfqPartsOrdered, (prt) => {
            prt.isAdditional = true
            partsOrdered.push(prt)
          })
          _.forEach(rfqCopy.partsOrdered, (prt) => {
            partsOrdered.push(prt)
          })

          let orders = {}
          _.forIn(rfqCopy.orders, (order, orderId) => {
            orders[orderId] = order
          })
          _.forIn(rfqCopy.parentOrders, (order, orderId) => {
            order.isParent = true
            orders[orderId] = order
          })
          _.forIn(rfqCopy.additionalOrders, (order, orderId) => {
            order.isAdditional = true
            orders[orderId] = order
          })

          if (!_.isEmpty(manualParts)) parts = parts.concat(manualParts)
          _.forIn(suppliers, (s) => {
            let orS = null
            _.forIn(this.supplierPrice.suppliers, (supplier) => {
              if (Number(supplier.id) === Number(s.id)) {
                orS = supplier
              }
            })
            if (orS?.clickedParts) {
              s.clickedParts = orS.clickedParts
            }
            if (orS?.unClickedParts) {
              s.unClickedParts = orS.unClickedParts
            }
          })
          let isAllowscsChk = _.cloneDeep(this.supplierPrice).isAllowscsChk
          this.supplierPrice = new SupplierPrice(suppliers, parts, orders, partsOrdered, this.rfq.status)
          this.supplierPrice.isAllowscsChk = isAllowscsChk

          this.supplierPrice.orderParts()

          if (rfq.selectedParts !== null) {
            if (!this.isSavingSelectedParts && !_.isEqual(this.supplierPrice.getSelectedPartsIds(), rfq.selectedParts)) {
              this.$nextTick(() => {
                this.rfq.selectedParts = rfq.selectedParts
                this.supplierPrice.selectPartsByIds(rfq.selectedParts)
              })
            } else if (this.isSavingSelectedParts) {
              this.rfq.selectedParts = this.savedSelectedParts
              this.supplierPrice.selectPartsByIds(this.savedSelectedParts)
            }
          } else if (isNeedSelectParts && !this.isAlternate) {
            if (this.rfq?.parts_to_suppliers && Object.keys(this.rfq.parts_to_suppliers).length > 0) {
              this.supplierPrice.selectAllPartsFrom(Object.keys(this.rfq.parts_to_suppliers)[0])
              this.saveSelectedParts(this.supplierPrice.getSelectedPartsIds())
            }
          }

          setTimeout(() => {
            if (!_.isEqual(this.selectedSuppliers, this.supplierPrice.selectedSuppliers)) {
              this.selectedSuppliers = _.cloneDeep(this.supplierPrice.selectedSuppliers)
            }
          }, 100)

        }
        setTimeout(() => {
          this.sendExtQpsRfqData(true)
        }, 500)
      },
      deep: true,
      immediate: true,
    }
  },
  components: {
    PdfViewer,
    VPerfectSignature,
    SearchFilter,
    VueEditor,
    Chat,
    RfqImportPricing,
    FileUpload,
    QuotePricingProgress,
    RfqTimer,
    StickyHeader,
    ImageLightbox,
    vueDropzone: vue2Dropzone,
    Multiselect,
    PurchaseDetail,
    RepairerPartListView,
    RepairerPartListViewOrdered,
    CustomerAccountDeliver,
    psLabelValue,
    DatePicker,
    'image-gallery': imageGallery,
    AdditionalInfo,
    BestPriceCountdown,
  }
}
</script>
<template>
  <div class="rfq-view-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>{{ pageTitle }}</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item home">
              <router-link to="/r">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{path:this.viewRfqsTableLink}">View Quotes</router-link>
            </li>
            <li class="breadcrumb-item active">
              <span class="d-inline-block d-lg-none">Edit {{ rfq.status | maxFour }} Quote</span>
              <span class="d-none d-lg-inline-block">Edit {{ rfq.status }} Quote</span>
            </li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <chat v-if="isShowChat"
                @closeChat="onCloseChat"
                :isShowChatInit="!!$route.params.isNeedOpenChat"
                :userName="getUserNameForChat"
                :messages="chatMessages"
                :is-chatter-online="chatSupplierIsOnline"
                :typers="chatTypers"
                @onChatMessageSubmit="onChatMessageSubmit"
                :class="{'chat-image-gallery active': selectedSchemeIndex != -1}"
                ref="repairer-rfq-view-chat"
                style="min-width: 118px"
                @onTyping="onChatInputTyping">
          </chat>

          <search-filter
              type="estimates"
              v-model="searchFilter"
              :limitLength="50"
              :filterValue="searchFilter"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              :placeholder="'Search'"
              :isSearch="true"
              :additionalMenu="additionalMenu"
              :debug="false"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>
      <!-- <div class="page-header-nav-options">
        <b-dropdown id="repairer-supplier-view-options" right text="Options" class="dropdown-options options-mobile"
                    :class="mobileOptionsClass">
          <template v-slot:button-content>Options
            <span class="dropdown-arrow">
                                    <i class='bx bx-down-arrow'></i>
                                     </span>
          </template>
          <b-dropdown-item
              v-if="isRFQReceived"
              @click="moveToSubmitQuote">Submit Quote
          </b-dropdown-item>
          <b-dropdown-item
              v-if="isRFQSubmitted"
              @click="moveToPriced">Move To Priced
          </b-dropdown-item>
          <b-dropdown-item v-if="isCurrentTabVehicleDetails && !isEditableVehicleDetails && !isRFQCancelled"
                           @click="editVehicleDetails">Edit Vehicle Details
          </b-dropdown-item>
          <b-dropdown-item
              v-if="!isEditableVehicleDetails && isRFQCancelled && rfq.quotePricingProgress.position===1"
              @click="reactivateQuote">Reactivate
          </b-dropdown-item>
          <b-dropdown-item
              v-if="!isEditableVehicleDetails && isRFQCancelled"
              @click="deleteQuote">Delete RFQ
          </b-dropdown-item>
          <b-dropdown-item
              v-if="ableToSendListPrices"
              @click="getListPriceClicked">Send List Price
          </b-dropdown-item>
          <b-dropdown-item
              v-if="!isEditableVehicleDetails && !isRFQCancelled && !isRFQOrdered"
              @click="cancelQuote">Cancel Quote
          </b-dropdown-item>
          <b-dropdown-item v-if="isCurrentTabPartList && !isEditablePartList" @click="editPartList">Edit Part
            List
          </b-dropdown-item>
          <b-dropdown-item v-if="isEditable" @click="cancelEdit">Cancel Edit</b-dropdown-item>
          <b-button v-if="isEditable" variant="primary" size="sm"
                    class="dropdown-menu__save-button d-block d-lg-none text-center" @click="clickSave">Save
          </b-button>
        </b-dropdown>
      </div> -->
      <div class="">
        <sticky-header
            v-if="isShowStickyHeader && !isShowPdfNR"
            :supplierPrice="supplierPrice"
            :isRFQOrdered="isRFQOrdered"
            :areaForOrderButton="areaForOrderButton"
            @select="priceClicked"
            @selectedPdf="selectedPdfNr"
            :orderedPriceSets="orderedPriceSets"
            @orderCreate="v => orderCreate(v, false)"
            @unselect="unSelectSupplier"
            :isRFQSubmitted="isRFQSubmitted"
            @openContextMenu="openContextMenu"
            :selectedPriceSet="selectedPriceSet"
            :isRFQPriced="isRFQPriced"
            :rfqId="rfq.id"
            :rfq="rfq"
            @openModal="openErrorModal"
            :status="rfq.status"
            :position="rfq.quotePricingProgress.position"
            @changedActiveSupplierId="changedActiveSupplierId"
            @handlePriceSetSelectorPressed="handlePriceSetSelectorPressed"
            :isAvailableOrderParts="isAvailableOrderParts"
            :isRfqSentToSingleSupplier="isRfqSentToSingleSupplier"
            :isQuoteExpired="isQuoteExpired"
            :paintCode="computedPaintCodeForRepair"
            :quoteNumber="rfq.QPNumber"
            :buildDate="rfq.vehicle.dom"
            :vin="rfq.vehicle.vin"
            :isReapirRFQStatus="this.rfq.status"
        ></sticky-header>
      </div>
    </div>
    <div class="rfq-view-body">
      <tabs
          :isNeedSaveInStorage="false"
          :options="{ useUrlFragment: false, defaultTabHash: 'part-list' }"
          @changed="changedTab"
          ref="rfq-view-tabs"
          :custom-counts="computedCustomCountForTab">
        <tab name="Vehicle Details"
             id="vehicle-details"
             :class-name="'tab-content-table'">
          <div class="row">
            <div class="col-12 col-lg-6 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="steps"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>

            <div class="w-100 d-block d-xl-none"></div>

            <div v-if="isRFQOrdered"
                 class="col-12 col-sm-6 col-lg-4">
              <div class="delivery-required-time">
                <ps-label-value label="Order Placed">{{
                    orderDateCreated | formatDatetime
                  }}
                </ps-label-value>
                <ps-label-value label="Parts Delivery Required">{{ orderDateDue }}</ps-label-value>
                <ps-label-value label="Special Instruction">
                  <div class="ps-scroll font-inter--bold p-0 special-instruction-block" v-html="orderInstruction"></div>
                </ps-label-value>
              </div>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments
                  :</label>
                <div class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <div class="send-direct-order-comment-block" style="min-height: auto">

                        <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                          <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                            <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                 v-html="rfq.directOrder.comment"></div>
                          </template>
                        </template>

                        <div class="p-2">
                          <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                            REQUEST</b></div>
                          <div class="pb-1">
                            <span style="font-weight: 600;">Comment: </span>
                          </div>
                          <span>{{ rfq.vehicle.comments }}</span>
                        </div>
                      </div>
                    </template>
                    <textarea
                        v-else
                        class="form-control"
                        placeholder=""
                        rows="3"
                        :disabled="!isEditableVehicleDetails"
                        v-model="rfq.vehicle.comments">
                    </textarea>
                  </template>
                </div>
              </div>
            </div>

            <div v-else
                 class="col-12 col-sm-6 col-lg-4">
              <rfq-timer :countdown="computedDueDate"
                         :dateDue="rfq.dateDue"
                         :isShowOpenDays="rfq.status === 'Priced'"
                         :openDays="rfq.quoteOpenDays"
                         :date-due-prop="rfq.dateDue"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isSentUpdate || isReceivedUpdate" class="comments-blinks-text"
                        :class="{'active': isSentUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span>
                </label>
                <div v-if="!isSentUpdate && !isReceivedUpdate" class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <div class="send-direct-order-comment-block" style="min-height: auto">

                        <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                          <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                            <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                 v-html="rfq.directOrder.comment"></div>
                          </template>
                        </template>

                        <div class="p-2">
                          <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                            REQUEST</b></div>
                          <div class="pb-1">
                            <span style="font-weight: 600;">Comment: </span>
                          </div>
                          <span>{{ rfq.vehicle.comments }}</span>
                        </div>
                      </div>
                    </template>
                    <textarea
                        v-else
                        class="form-control"
                        placeholder=""
                        rows="3"
                        :disabled="!isEditableVehicleDetails"
                        v-model="rfq.vehicle.comments">
                    </textarea>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  :customer="companyInfo.businessName"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <additional-info :paintCode="computedPaintCodeForRepair" :quoteNumber="rfq.QPNumber"
                           :buildDate="rfq.vehicle.dom" :vin="rfq.vehicle.vin"
                           :isReapirRFQStatus="this.rfq.status"></additional-info>

          <div class="d-flex repairer-vehicle-block">
            <div style="width: 569px;" class="vehicle-details-block block pb-2">
              <div class="block-header bg-navyblue">
                Vehicle Details
              </div>
              <div class="block-body bg-white">
                <div class="form-group row">
                  <label class="col col-form-label">Quoting Package Nbr</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control"
                           placeholder=""
                           v-model="rfq.QPNumber"
                           @change="upperCase(rfq, 'QPNumber')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Rego</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="rfq.vehicle.rego"
                           @change="upperCase(rfq.vehicle, 'rego')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">VIN Number</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control"
                           placeholder=""
                           v-model="rfq.vehicle.vin"
                           @change="upperCase(rfq.vehicle, 'vin')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Build Date</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="" v-model="rfq.vehicle.dom"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Make</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control"
                           placeholder=""
                           v-model="rfq.vehicle.make"
                           @change="upperCase(rfq.vehicle, 'make')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Model</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control"
                           placeholder=""
                           v-model="rfq.vehicle.model"
                           @change="upperCase(rfq.vehicle, 'model')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Series</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control"
                           placeholder=""
                           v-model="rfq.vehicle.series"
                           @change="upperCase(rfq.vehicle, 'series')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Colour</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="rfq.vehicle.colour"
                           @change="upperCase(rfq.vehicle, 'colour')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Body</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="rfq.vehicle.body"
                           @change="upperCase(rfq.vehicle, 'body')"
                           style="text-transform: uppercase;"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Transmission</label>
                  <div class="col-sm-8">
                    <multiselect
                        :disabled="!isEditableVehicleDetails"
                        ref="viewRfqVehicleDetailsTransmission"
                        v-model="rfq.vehicle.transmission"
                        :options="['Automatic','Manual']"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        v-on:keyup.up="changePositionByArrowKey('viewRfqVehicleDetailsTransmission',$event)"
                        v-on:keyup.down="changePositionByArrowKey('viewRfqVehicleDetailsTransmission',$event)"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Odometer</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="rfq.vehicle.odometer" :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Insurer</label>
                  <div class="col-sm-8">
                    <multiselect
                        :disabled="!isEditableVehicleDetails"
                        ref="viewRfqVehicleDetailsInsurer"
                        v-model="rfq.vehicle.insurer"
                        :options="insurerList"
                        placeholder="Select insurer"
                        :taggable="true"
                        tag-placeholder="+ New Insurer"
                        @tag="addedNewInsurer"
                        :showLabels="false"
                        class="repairer-insurer-multiselect"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        v-on:keyup.up="changePositionByArrowKey('viewRfqVehicleDetailsInsurer',$event)"
                        v-on:keyup.down="changePositionByArrowKey('viewRfqVehicleDetailsInsurer',$event)"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Customer Claim Nbr</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control" placeholder=""
                           v-model="rfq.vehicle.claimNumber"
                           @focus="isClaimNumberFieldFocused = true"
                           @blur="isClaimNumberFieldFocused = false"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Estimator</label>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control" placeholder=""
                           v-model="rfq.estimator"
                           :disabled="!isEditableVehicleDetails">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Cutt-Off for Quote</label>
                  <div class="col-sm-8">
                    <input v-if="rfq && rfq.cuttOff && rfq.cuttOff.includes('01/01/1970')" type="text"
                           class="form-control"
                           placeholder=""

                           :disabled="!isEditableVehicleDetails">
                    <date-picker v-else
                                 v-model="rfq.cuttOff"
                                 :isDateTime="true"
                                 :placeHolderText="'hh:mm || dd/mm/yyyy'"
                                 :isDisabled="!isEditableVehicleDetails"></date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="false && _toUpper(rfq.vehicle.insurer) === _toUpper('Toyota Insurance')" @click="openPdfInNewTab"
                 style="cursor: pointer;" class="insurer-text-block">
              <p class="font-weight-bold">The Toyota Insurance Lifetime Repair Guarantee</p>
              <p class="mb-0">Toyota will always use new genuine parts sourced through the manufacturer’s Australian
                distribution channel in the repair of your vehicle, except for the replacement of windscreens, sunroofs
                and window glass where Australian Design Rule compliant parts may be used.</p>
            </div>
          </div>

        </tab>
        <tab name="Part List"
             id="part-list"
             :class-name="'tab-content-table'">
          <div v-if="!isShowImportWizard && !isShowPdfNR" class="row">
            <div class="col-12 col-lg-6 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="steps"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>

            <div class="w-100 d-block d-xl-none"></div>
            <div v-if="isRFQOrdered"
                 class="col-12 col-sm-6 col-lg-4">
              <div class="delivery-required-time">
                <ps-label-value label="Order Placed">{{
                    orderDateCreated | formatDatetime
                  }}
                </ps-label-value>
                <ps-label-value label="Parts Delivery Required">{{ orderDateDue }}</ps-label-value>
                <ps-label-value label="Special Instruction">
                  <div class="ps-scroll font-inter--bold p-0 special-instruction-block" v-html="orderInstruction"></div>
                </ps-label-value>
              </div>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :</label>
                <div class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <div class="send-direct-order-comment-block" style="min-height: auto">

                        <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                          <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                            <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                 v-html="rfq.directOrder.comment"></div>
                          </template>
                        </template>

                        <div class="p-2">
                          <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                            REQUEST</b></div>
                          <div class="pb-1">
                            <span style="font-weight: 600;">Comment: </span>
                          </div>
                          <span>{{ rfq.vehicle.comments }}</span>
                        </div>
                      </div>
                    </template>
                    <textarea
                        v-else
                        class="form-control"
                        placeholder=""
                        rows="3"
                        :disabled="!isEditableVehicleDetails"
                        v-model="rfq.vehicle.comments">
                    </textarea>
                  </template>
                </div>
              </div>
            </div>

            <div v-else
                 class="col-12 col-sm-6 col-lg-4">

              <rfq-timer :countdown="computedDueDate"
                         :isShowOpenDays="rfq.status === 'Priced'"
                         :openDays="rfq.quoteOpenDays"
                         :date-due-prop="rfq.dateDue"
                         :dateDue="rfq.dateDue"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isSentUpdate || isReceivedUpdate" class="comments-blinks-text"
                        :class="{'active': isSentUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span>
                </label>
                <div v-if="!isSentUpdate && !isReceivedUpdate" class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template
                        v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                      <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                        <div v-html="rfq.directOrder.comment"></div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                        <div class="send-direct-order-comment-block" style="min-height: auto">

                          <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                            <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                              <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                   v-html="rfq.directOrder.comment"></div>
                            </template>
                          </template>

                          <div class="p-2">
                            <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                              REQUEST</b></div>
                            <div class="pb-1">
                              <span style="font-weight: 600;">Comment: </span>
                            </div>
                            <span>{{ rfq.vehicle.comments }}</span>
                          </div>
                        </div>
                      </template>
                      <textarea
                          v-else
                          class="form-control"
                          placeholder=""
                          rows="3"
                          :disabled="!isEditableVehicleDetails"
                          v-model="rfq.vehicle.comments">
                    </textarea>
                    </template>
                  </template>
                </div>
              </div>
                <template
                    v-if="isRFQPriced  && (rfq.secondRequestBestPrice === true || rfq.secondRequestBestPrice === false ||  rfq.thirdRequestBestPrice === true || rfq.thirdRequestBestPrice === false)">
                  <best-price-countdown
                      :endTime="rfq.thirdRequestBestPriceTimeEnd || rfq.secondRequestBestPriceTimeEnd"
                      :isSecondStart="(rfq.secondRequestBestPrice === true)"
                      :isSecondEnd="(rfq.secondRequestBestPrice === false)"
                      :isThirdStart="(rfq.thirdRequestBestPrice === true && rfq.secondRequestBestPrice === false)"
                      :isThirdEnd="(rfq.thirdRequestBestPrice === false && rfq.secondRequestBestPrice === false)"
                      :isRepairer = true
                      @endCountdown="endCountdown"
                  />
                </template>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  :customer="companyInfo.businessName"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <div v-if="!isShowImportWizard && !isShowPdfNR" class="" id="additional-info">
            <additional-info :paintCode="computedPaintCodeForRepair" :quoteNumber="rfq.QPNumber"
                             :buildDate="rfq.vehicle.dom"
                             ref="additionalInfo"
                             :vin="rfq.vehicle.vin"></additional-info>
          </div>

          <div v-if="!isShowImportWizard && !isShowPdfNR" class="part-list" id="part-list-wrapper">
            <div v-if="supplierPrice.parts" class="row no-gutter">
              <div class="col-12 col-lg-auto px-0 order-md-1 table-width"
                   style="margin-right:10px;">
                <repairer-part-list-view-ordered v-if="isRFQOrdered"
                                                 :supplier-price="supplierPrice"
                                                 :manual-parts="rfq.manualParts"
                                                 :rfqOrders="computedRfqOrders"
                                                 :purchasePriceSelector="rfq.purchasePriceSelector"
                                                 :isPartListTab="activeTab == 'Part List'"
                                                 :orderedPriceSets="orderedPriceSets"
                                                 @handlePriceSetSelectorPressed="handlePriceSetSelectorPressed"
                                                 :selectedPriceSet="selectedPriceSet"
                                                 :status="rfq.status"
                                                 :rfqId="rfq.id"
                                                 :rfq="rfq"
                                                 :position="rfq.quotePricingProgress.position"
                ></repairer-part-list-view-ordered>
                <template v-else>
                  <div class="">

                  </div>
                  <repairer-part-list-view
                      :supplier-price="supplierPrice"
                      :manual-parts="rfq.manualParts"
                      :isRFQReceived="isRFQReceived"
                      :isRFQSubmitted="isRFQSubmitted"
                      :isRFQCancelled="isRFQCancelled"
                      :isPartListTab="activeTab == 'Part List'"
                      :isRFQPriced="isRFQPriced"
                      :isDirectOrder="isDirectOrder"
                      :rfqOrders="computedRfqOrders"
                      :rfqId="rfq.id"
                      :rfq="rfq"
                      @select="priceClicked"
                      @unselect="unSelectSupplier"
                      @showImportWizard="isShowImportWizard = true"
                      @importedParts="importedPartsForNRSupp"
                      @openModal="openErrorModal"
                      :status="rfq.status"
                      :purchasePriceSelector="rfq.purchasePriceSelector"
                      :position="rfq.quotePricingProgress.position"
                      @click="orderCreate"
                      @savePart="savePart"
                      @removePart="removePart"
                      :isAvailableOrderParts="isAvailableOrderParts"
                      ref="partList"
                      @selectedPdf="selectedPdfNr"
                      @unlockRequestBestPrice="unlockRequestBestPrice"
                      @lockRequestBestPrice="lockRequestBestPrice"
                  ></repairer-part-list-view>
                </template>
              </div>
              <div v-if="isRFQPriced" class="col-12 col-lg-auto order-md-2 px-lg-3 best-value" id="price-buttons">
                <b-button-group vertical class="tab__button-group w-100">
                  <b-button v-for="priceSet in priceSets"
                            :key="priceSet.name"
                            class="tab__button"
                            :disabled="(priceSet.name === 'selection' && (countOfSelectedParts == 0 || isSelectedNRSuppParts))"
                            :class="{
                              'active': ((selectedPriceSet === priceSet.name && priceSet.name !== 'selection' && isRfqSentToMultipleSupplier) || (priceSet.name === 'selection')) || ( priceSet.name === 'selection' && isRfqSentToSingleSupplier) ,
                              'active-ordered-button': priceSet.name === 'selection',
                              'active-ordered-button-2': (countOfSelectedParts == 0 || isSelectedNRSuppParts) && priceSet.name === 'selection',
                              'selection-button':isRfqSentToSingleSupplier,
                              'rfq-order-button-blinked-orange': priceSet.name === 'selection' && isQuoteExpired}"
                            block
                            :id="priceSet.name === 'selection' ? 'selection-button': priceSet.name"
                            variant="outline-primary"
                            :ref="priceSet.name"
                            @click="handlePriceSetSelectorPressed(priceSet.name)">

                    {{ priceSet.label }}

                    <i
                        v-if="priceSet.name === 'selection' && isQuoteExpired"
                        v-b-tooltip.hover="{
                                                customClass: 'ps-tooltip',
                                                html: true,
                                                title: 'Quote Expired by the Supplier',
                                                placement:'leftbottom'
                                              }"
                        class="bx bx-error-alt">
                    </i>
                  </b-button>
                </b-button-group>
                <div class="block-detail-group">
                  <purchase-detail title="OEM-Parts"
                                   style="margin-bottom: 25px;"
                                   :buy-price="supplierPrice.OEMPartsPurchase.buyPrice"
                                   :sell-price="supplierPrice.OEMPartsPurchase.sellPrice"
                                   :mark-up="supplierPrice.OEMPartsPurchase.markUp"
                                   :isOEMParts="true" deleteFileIds
                                   :profit="supplierPrice.OEMPartsPurchase.profit"
                  ></purchase-detail>
                  <purchase-detail title="Selected-Part"
                                   style="border: 1px solid rgba(27, 30, 56, 0.25)"
                                   :isOEMParts="false"
                                   v-if="isShowSelectedPartSection"
                                   :isAvailableOrderParts="isAvailableOrderParts"
                                   :buy-price="supplierPrice.selectedPartsPurchase.buyPrice"
                                   :sell-price="supplierPrice.selectedPartsPurchase.sellPrice"
                                   :mark-up="supplierPrice.selectedPartsPurchase.markUp"
                                   :profit="supplierPrice.selectedPartsPurchase.profit">
                  </purchase-detail>
                </div>
              </div>

              <div v-if="isRFQPriced || isRFQSubmitted && (computedPaintCodeForRepair === 'B74P' || computedPaintCodeForRepair === '46V' || computedPaintCodeForRepair === '41V' || computedPaintCodeForRepair === 'NH883P')" class="col-12 col-lg-auto order-1 order-md-2 px-lg-3 best-value img-code-paint-container">
                <img v-if="computedPaintCodeForRepair === 'B74P' || rfq.vehicle.colour === 'B74P'"  src="@/assets/img/paints/B74P.png">
                <img v-else-if="computedPaintCodeForRepair === '46V' || rfq.vehicle.colour === '46V'" src="@/assets/img/paints/46V.png">
                <img v-else-if="computedPaintCodeForRepair === '41V' || rfq.vehicle.colour === '41V'" src="@/assets/img/paints/41V.png">
                <img v-else-if="computedPaintCodeForRepair === 'NH883P' || rfq.vehicle.colour === 'NH883P'" src="@/assets/img/paints/NH883P.png">

              </div>

            </div>
          </div>

          <rfq-import-pricing
              v-if="isShowImportWizard"
              @close="isShowImportWizard = false"
              @setRrp="setRrp"
              @setValueForOffer="setValueForOffer"
              :pdfFile="currentPDFFile"
              :importedParts="getImportedParts()"
              :original-parts="getOriginalParts()"
              :supplierId="currentNRSuppId"
              :rfqId="rfq.id"
              :importedPDF="importedPDF"
              :supplierPrice="supplierPrice"
              :isOemGen="isOemGenSupp(currentNRSuppId)"
          ></rfq-import-pricing>

          <pdf-viewer
              v-if="isShowPdfNR"
              :documentHeight="documentHeight"
              :documentWidth="documentWidth"
              :documentPath="selectedNRPDFUrl"
              @closePdf="isShowPdfNR = false"
          ></pdf-viewer>
        </tab>
        <tab name="Images"
             id="images"
             :class-name="'tab-content-table'">
          <div class="row">
            <div class="col-12 col-lg-6 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="steps"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>
            <div class="w-100 d-block d-xl-none"></div>

            <div v-if="isRFQOrdered"
                 class="col-12 col-sm-6 col-lg-4">
              <div class="delivery-required-time">
                <ps-label-value label="Order Placed">{{
                    orderDateCreated | formatDatetime
                  }}
                </ps-label-value>
                <ps-label-value label="Parts Delivery Required">{{ orderDateDue }}</ps-label-value>
                <ps-label-value label="Special Instruction">
                  <div class="ps-scroll font-inter--bold p-0 special-instruction-block" v-html="orderInstruction"></div>
                </ps-label-value>
              </div>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">
                  Comments :
                </label>
                <div class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <div class="send-direct-order-comment-block" style="min-height: auto">

                        <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                          <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                            <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                 v-html="rfq.directOrder.comment"></div>
                          </template>
                        </template>

                        <div class="p-2">
                          <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                            REQUEST</b></div>
                          <div class="pb-1">
                            <span style="font-weight: 600;">Comment: </span>
                          </div>
                          <span>{{ rfq.vehicle.comments }}</span>
                        </div>
                      </div>
                    </template>
                    <textarea
                        v-else
                        class="form-control"
                        placeholder=""
                        rows="3"
                        :disabled="!isEditableVehicleDetails"
                        v-model="rfq.vehicle.comments">
                    </textarea>
                  </template>
                </div>
              </div>
            </div>
            <div v-else
                 class="col-12 col-sm-6 col-lg-4">
              <rfq-timer :countdown="computedDueDate"
                         :isShowOpenDays="rfq.status === 'Priced'"
                         :openDays="rfq.quoteOpenDays"
                         :date-due-prop="rfq.dateDue"
                         :dateDue="rfq.dateDue"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isSentUpdate || isReceivedUpdate" class="comments-blinks-text"
                        :class="{'active': isSentUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span>
                </label>
                <div v-if="!isSentUpdate && !isReceivedUpdate" class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template
                        v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                      <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                        <div v-html="rfq.directOrder.comment"></div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                        <div class="send-direct-order-comment-block" style="min-height: auto">

                          <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                            <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                              <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                   v-html="rfq.directOrder.comment"></div>
                            </template>
                          </template>

                          <div class="p-2">
                            <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                              REQUEST</b></div>
                            <div class="pb-1">
                              <span style="font-weight: 600;">Comment: </span>
                            </div>
                            <span>{{ rfq.vehicle.comments }}</span>
                          </div>
                        </div>
                      </template>
                      <textarea
                          v-else
                          class="form-control"
                          placeholder=""
                          rows="3"
                          :disabled="!isEditableVehicleDetails"
                          v-model="rfq.vehicle.comments">
                    </textarea>
                    </template>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  :customer="companyInfo.businessName"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <additional-info :paintCode="computedPaintCodeForRepair" :quoteNumber="rfq.QPNumber"
                           :buildDate="rfq.vehicle.dom"
                           :vin="rfq.vehicle.vin"></additional-info>

          <file-upload v-if="rfq.id"
                       :isNeedChangePosition="true"
                       :read-only="isRFQCancelled"
                       v-model="rfq.images"
                       @removedFiles="removedFiles"
                       :object-id="Number(rfq.id)"
                       :isCanPin="rfq.properties.isCanPinImages"
                       :pinnedImage="rfq.pinImage"
                       @onPinnedImage="onPinnedImage"
                       type="image"></file-upload>
        </tab>
        <tab
            id="files"
            :isShow="isRFQSubmitted || isRFQPriced || isRFQOrdered || isRFQCancelled"
            name="Files"
            :class-name="'tab-content-table'">
          <div v-if="!isShowPdf" class="row">
            <div class="col-12 col-lg-6 part-list-qoute-pricing-progress">
              <quote-pricing-progress
                  :steps="steps"
                  :step="currentQPPStep">
              </quote-pricing-progress>
            </div>

            <div class="w-100 d-block d-xl-none"></div>

            <div v-if="isRFQOrdered"
                 class="col-12 col-sm-6 col-lg-4">
              <div class="delivery-required-time">
                <ps-label-value label="Order Placed">{{
                    orderDateCreated | formatDatetime
                  }}
                </ps-label-value>
                <ps-label-value label="Parts Delivery Required">{{ orderDateDue }}</ps-label-value>
                <ps-label-value label="Special Instruction">
                  <div class="ps-scroll font-inter--bold p-0 special-instruction-block" v-html="orderInstruction"></div>
                </ps-label-value>
              </div>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments
                  :</label>
                <div class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <div class="send-direct-order-comment-block" style="min-height: auto">

                        <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                          <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                            <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                 v-html="rfq.directOrder.comment"></div>
                          </template>
                        </template>

                        <div class="p-2">
                          <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                            REQUEST</b></div>
                          <div class="pb-1">
                            <span style="font-weight: 600;">Comment: </span>
                          </div>
                          <span>{{ rfq.vehicle.comments }}</span>
                        </div>
                      </div>
                    </template>
                    <textarea
                        v-else
                        class="form-control"
                        placeholder=""
                        rows="3"
                        :disabled="!isEditableVehicleDetails"
                        v-model="rfq.vehicle.comments">
                    </textarea>
                  </template>
                </div>
              </div>
            </div>

            <div v-else
                 class="col-12 col-sm-6 col-lg-4">
              <rfq-timer :countdown="computedDueDate"
                         :date-due-prop="rfq.dateDue"
                         :isShowOpenDays="rfq.status === 'Priced'"
                         :openDays="rfq.quoteOpenDays"
                         :dateDue="rfq.dateDue"
              >
              </rfq-timer>
              <div class="form-group row">
                <label class="col col-form-label pl-0 color-lightgray" style="font-size: 1rem;">Comments :
                  <span v-if="isSentUpdate || isReceivedUpdate" class="comments-blinks-text"
                        :class="{'active': isSentUpdate}">Update RFQ <span
                      class="comments-after-blinks-text">requested</span></span></label>
                <div v-if="!isSentUpdate && !isReceivedUpdate" class="col-9">
                  <template
                      v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder && !rfq.properties.isAskForPartsDiagram">
                    <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                      <div v-html="rfq.directOrder.comment"></div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="rfq && rfq.properties && rfq.properties.isAskForPartsDiagram">
                      <div class="send-direct-order-comment-block" style="min-height: auto">

                        <template v-if="rfq && rfq.properties && rfq.properties.isSentAsDirectOrder">
                          <template v-if="rfq && rfq.directOrder && rfq.directOrder.comment">
                            <div class="rfq-view-direct-order-comment rfq-view-direct-order-comment-2"
                                 v-html="rfq.directOrder.comment"></div>
                          </template>
                        </template>

                        <div class="p-2">
                          <div class="pb-3" :class="{'pt-3': rfq.properties.isSentAsDirectOrder}"><b>PARTS DIAGRAM
                            REQUEST</b></div>
                          <div class="pb-1">
                            <span style="font-weight: 600;">Comment: </span>
                          </div>
                          <span>{{ rfq.vehicle.comments }}</span>
                        </div>
                      </div>
                    </template>
                    <textarea
                        v-else
                        class="form-control"
                        placeholder=""
                        rows="3"
                        :disabled="!isEditableVehicleDetails"
                        v-model="rfq.vehicle.comments">
                    </textarea>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-lg ml-lg-0 m-lg-0">
              <customer-account-deliver
                  :customer="companyInfo.businessName"
                  account=""
                  :delivery-to="deliveryTo"
                  :estimator="rfq.estimator"
              ></customer-account-deliver>
            </div>
            <div class="wizard__divider"></div>
          </div>

          <additional-info v-if="!isShowPdf" :paintCode="computedPaintCodeForRepair" :quoteNumber="rfq.QPNumber"
                           :buildDate="rfq.vehicle.dom"
                           :vin="rfq.vehicle.vin"></additional-info>

          <div class="row" :class="{'repairer-view-files-wrapper':  isHasSchemeImage}">
            <file-upload
                v-if="rfq.id"
                :read-only="true"
                v-model="rfq.files"
                ref="files-upload"
                :object-id="Number(rfq.id)"
                @updatedFiles="filterRfqFiles"
                @selectedRows="selectedFilesRows"
                :isSelectable="true"
                :documentHeight="documentHeight"
                :documentWidth="documentWidth"
                @openPDF="openPdf"
                :isRepairerDiagram="isHasSchemeImage"
                type="files"
                class="col-lg-6 dropzone-width-50"></file-upload>

            <div v-if="!isShowPdf" class="col-lg-6 diagram-block-image files-diagram-wrapper">
              <div v-if="isHasSchemeImage && schemeImage" class="files-diagram-block">

                <img
                    @click="selectSchemeImage(false)"
                    class="diagram-image"
                    :src="schemeImage.previewUrl"/>
                <div class="diagram-block-line"></div>

                <div class="diagram-block-button">
                  <div class=""><span>Added to Quote by your supplier.</span></div>
                  <button type="button" @click="selectSchemeImage(true)" style="margin: 15px"
                          class="btn btn-primary waves-effect waves-light browse-btn">Edit
                  </button>
                </div>
              </div>
            </div>

            <template v-if="selectedSchemeIndex != -1 && !isShowPdf">
              <div class="row">
                <div class="col-9">
                  <image-gallery :use-editor="true"
                                 :isDownload="true"
                                 :isRemove="false"
                                 :images="computedSchemeImages"
                                 :pinnedImage="[]"
                                 :current-index="selectedSchemeIndex"
                                 @close-gallery="selectedSchemeIndex = -1"
                                 :rfqId="rfq.id"
                                 ref="image-gallery"
                  >
                  </image-gallery>
                </div>
                <div class="col-3"></div>
              </div>
            </template>
          </div>

        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-4">
            <a class="btn btn-primary clickable d-none d-lg-inline-block"
               v-if="isEditable"
               @click="clickSave"
               style=" vertical-align: middle; padding-left:25px; padding-right:25px;">
              Save
            </a>
          </div>
          <div class="tabs-nav-right-options"
               :class="{'large-options-width': isShowSendToQuotingPackage1 || isShowSendToQuotingPackage2 || isShowIBSOptions}">
            <b-dropdown @hide="isShowRequestRFQUpdate = false" id="repairer-supplier-view-options"
                        ref="repairer-supplier-view-options"
                        :disabled="isShowImportWizard"
                        text="Options" class="dropdown-options options-mobile dropdown-actions">
              <template v-slot:button-content>
                Actions
                <span class="dropdown-arrow">
                                    <i class='bx bx-down-arrow'></i>
                                </span>
              </template>
              <b-dropdown-item
                  v-if="(isRFQPriced) && isHasNRSuppParts && rfq.secondRequestBestPrice !== false && rfq.secondRequestBestPrice !== true"
                  @click="requestBestPrice"
              >BEST PRICE - REQUEST
              </b-dropdown-item>
              <div @mouseover="onHover" @mouseleave="onMouseLeave" class="">
                <template v-if="isRFQOrdered">
                  <b-dropdown-item
                      class="request-rfq-update-wrapper"
                  >
                    <i style="top: 3px; left: 2px;" class='bx bx-chevron-left font-18'></i>View Order
                  </b-dropdown-item>
                  <template v-if="isOnHoverOptions">
                    <div
                        class="repairer-request-update-context" style="right: 143px"
                        :class="{
                      'repairer-request-update-context-2': isShowSendToQuotingPackage1 || isShowSendToQuotingPackage2 || isShowIBSOptions
                    }"
                    >
                      <b-button
                          v-for="supplier in supplierPrice.orderedSuppliers"
                          :key="supplier.orderId"
                          v-if="supplier.orderId"
                          style="white-space: nowrap"
                          class="text-left font-12 font-inter--semibold repairer-request-update-context-btn"
                          :to="'/r/order/' + supplier.orderId"
                          variant="outline-primary" block>
                        {{ supplier.orderNumber }} {{ supplier.name }}
                      </b-button>
                    </div>
                  </template>
                </template>

                <template v-if="isAlreadySentRequestRFQUpdate">
                  <b-dropdown-item
                      v-if="rfq.status === 'Priced'"
                      :class="{'disabled-button-request-rfq': !isCanRequestRFQUpdate}"
                      :disabled="!isCanRequestRFQUpdate"
                      class="request-rfq-update-wrapper"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'All the available requests are already sent', placement:'leftbottom'}"
                  >
                    <i style="top: 3px; left: 2px;" class='bx bx-chevron-left font-18'></i>Request RFQ Update
                  </b-dropdown-item>
                </template>
                <template v-else>
                  <b-dropdown-item
                      v-if="rfq.status === 'Priced'"
                      :class="{'disabled-button-request-rfq': !isCanRequestRFQUpdate}"
                      :disabled="!isCanRequestRFQUpdate"
                      class="request-rfq-update-wrapper"
                  >
                    <i style="top: 3px; left: 2px;" class='bx bx-chevron-left font-18'></i>Request RFQ Update
                  </b-dropdown-item>
                </template>
                <div
                    v-if="isShowRequestRFQUpdate"
                    class="repairer-request-update-context"
                    :class="{
                      'repairer-request-update-context-2': isShowSendToQuotingPackage1 || isShowSendToQuotingPackage2 || isShowIBSOptions
                    }"
                >
                  <b-button
                      v-if="supplierPrice && supplierPrice.orderedSuppliersForRequestUpdate && supplierPrice.orderedSuppliersForRequestUpdate.length > 1"
                      :class="{
                                              'disabled-button-supplier-update': !isCanRequestRfqUpdateToAll
                                            }"
                      :disabled="!isCanRequestRfqUpdateToAll"
                      class="text-left font-12 font-inter--semibold repairer-request-update-context-btn"
                      @click="requestRfqUpdate(-1)"
                      variant="outline-primary" block>
                    All
                  </b-button>
                  <b-button
                      v-for="supplier in supplierPrice.orderedSuppliersForRequestUpdate"
                      :key="supplier.supplierId"
                      class="text-left font-12 font-inter--semibold repairer-request-update-context-btn"
                      @click="requestRfqUpdate([supplier.supplierId])"
                      :disabled="!isSupplierExpired(supplier)"
                      :class="{
                                              'disabled-button-supplier-update': !isSupplierExpired(supplier)
                                            }"
                      variant="outline-primary" block>
                    {{ supplier.name }}
                  </b-button>
                </div>
              </div>
              <b-dropdown-item v-if="isShowIBSOptions && (isRFQSubmitted || isRFQPriced)" @click="sendPricingToIBS">Send
                Back Pricing to IBODYSHOP
              </b-dropdown-item>
              <b-dropdown-item v-if="isShowIBSOptions && isRFQOrdered" @click="createOrderInIBS">Send to IBODYSHOP
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="isRFQReceived"
                  @click="moveToSubmitQuote">Submit Quote
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="isRFQSubmitted"
                  @click="moveToPriced">Move To Priced
              </b-dropdown-item>
              <!--                            <b-dropdown-item-->
              <!--                                    v-if="isShowTransferListPrice"-->
              <!--                                    @click="transferListPrice">Transfer List Price-->
              <!--                            </b-dropdown-item>-->
              <b-dropdown-item
                  v-if="isShowSendToQuotingPackage1"
                  @click="sendToQuotingPackage(false)">Send back List to Repair-Shop
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="isShowSendToQuotingPackage2"
                  @click="sendToQuotingPackage(true)">Send back List & Buy to Repair-Shop
              </b-dropdown-item>
              <b-dropdown-item
                  class="partsbridge-interface"
                  style="display: none"
                  @click="exportRfqToPartsbridge">Export To PB Ext
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="isCurrentTabVehicleDetails && !isEditableVehicleDetails && !isRFQCancelled"
                  @click="editVehicleDetails">Edit Vehicle Details
              </b-dropdown-item>
              <!--              <b-dropdown-item-->
              <!--                  v-if="ableToSendListPrices"-->
              <!--                  @click="getListPriceClicked">Send List Price-->
              <!--              </b-dropdown-item>-->
              <b-dropdown-item
                  v-if="!isEditableVehicleDetails && !isRFQCancelled && !isRFQOrdered && !isRFQReceived"
                  @click="cancelQuote">Cancel Quote
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="!isEditableVehicleDetails && isRFQCancelled && rfq.quotePricingProgress.position===1"
                  @click="reactivateQuote">Reactivate
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="!isEditableVehicleDetails && (isRFQCancelled || isRFQReceived)"
                  @click="deleteQuote">Delete RFQ
              </b-dropdown-item>
              <!-- <b-dropdown-item v-if="isCurrentTabPartList && !isEditablePartList" @click="editPartList">
                   Edit Part List
               </b-dropdown-item>-->
              <b-dropdown-item v-if="isEditable" @click="cancelEdit">Cancel Edit</b-dropdown-item>
              <b-button v-if="isEditable" variant="primary" size="sm"
                        class="dropdown-menu__save-button d-block d-lg-none text-center"
                        @click="clickSave">Save
              </b-button>
            </b-dropdown>
          </div>
        </template>
      </tabs>
    </div>

    <b-modal ref="quote-expired-warning" size="quote-expired-warning">
      <template v-slot:modal-header>
        <h5 class="modal-title">Quote Expired - Stock and Pricing Update available</h5>
      </template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3" style="margin-bottom: 35px; margin-top: 15px;">Partssearch can get suppliers that priced
          this quote to provide an update in <b>Stock Available</b> and <b>Pricing</b> before you place an order.</p>
        <div class="pl-3 pr-3 d-flex" style="margin-bottom: 25px">
          <div class="" style="width: 40%;">
            <p><b>Request RFQ Update</b></p>
            <p>Suppliers will be notified & given a short time to respond</p>
          </div>
          <div class="expired-modal-supps-section">
            <template v-if="expiredSupps.length >= 1">
              <div v-if="expiredSupps.length > 1" class="d-flex justify-content-between align-items-center">
                <div class="">Send to All</div>
                <div class="">
                  <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                    <input class="form-check-input" v-model="expiredSuppsCheckAll"
                           @change="onExpiredSuppsCheckAllChanged" type="checkbox">
                    <span class="icon clickable" style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div v-for="supplier in expiredSupps" :key="supplier.supplierId"
                   class="d-flex justify-content-between align-items-center">
                <div class=""> {{ supplier.name }}</div>
                <div class="">
                  <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                    <input class="form-check-input" v-model="expiredSuppsIds" @change="onExpiredSuppsCheckChanged"
                           :value="supplier.supplierId" type="checkbox">
                    <span class="icon clickable" style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
            </template>
            <template v-else>All the available requests are already sent</template>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no modal-undo-part-line-change-btn-cancel"
                  @click="cancel()">Cancel
          </button>
          <button v-if="expiredSupps.length >= 1" @click="requestRfqUpdate(expiredSuppsIds); cancel()"
                  :disabled="expiredSuppsIds.length == 0" style="color: white !important;"
                  class="clickable input-btn modal-undo-part-line-change-btn-yes modal-undo-part-line-change-btn-update">
            Get Update
          </button>
          <button @click="orderCreate(supplierPrice.getSelectedPartsIds()) " style="color: white !important;"
                  class="input-btn modal-undo-part-line-change-btn-yes modal-undo-part-line-change-btn-now">Order Now
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="send-order-back" size="send-order-back-to-ibs">
      <template v-slot:modal-header>
        <h5 class="modal-title">Send Order Back to IBODYSHOP</h5>
      </template>
      <div class="form-container">
        <p class="pl-3 pr-3">Do you want to send Order/s back to IBODYSHOP</p>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-send-order-to-ibs-btn-no" @click="cancel()">No</button>
          <button class="input-btn modal-send-order-to-ibs-btn-yes">Yes</button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="non-returnable-agreement" size="quote-expired-warning">
      <template v-slot:modal-header>
        <h5 class="modal-title">Non-Returnable - Part Acceptance Agreement</h5>
      </template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3">Part/s chosen for this Quote have been flagged as Non-returnable from your Parts
          Supplier.</p>
        <p class="pl-3 pr-3">Review and accept the agreement before this order can be placed.</p>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" style="border-color: #79B1BB" @click="cancel()">
            No
          </button>
          <button class="input-btn modal-undo-part-line-change-btn-yes" @click="showNonReturnableModal"
                  style="border-color: #79B1BB">Continue
          </button>
        </div>
      </template>
    </b-modal>

    <template v-if="supplierPrice && selectedSuppliers && computedSelectedNRSupps">
      <b-modal v-for="(s, ind) in computedSelectedNRSupps" :key="s.id" :ref="'non-returnable-example-' + s.id"
               :id="'non-returnable-modal-' + s.id"
               size="custom-non-returnable-modal repairer-custom-non-returnable-modal"
               @hide="isOpenNonReturnalbleModal = false"
               title="Non-Returnable - Part Acceptance Agreement">
        <template v-slot:modal-backdrop class="my-backdrop"></template>

        <div class="modal-mark-as-returned non-returnable-supplier-modal form-container">
          <form>
            <div class="form-group row pr-3">
              <label class="col col-form-label"> {{ s.name }} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the
            supply of the following part/s with your order.</span></label>
            </div>
            <div class="form-group row pr-3">
              <label class="col col-form-label"><span class="font-weight-normal">Select the part/s in the list below to accept.</span></label>
            </div>
            <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Quote Number: </p>
                <p class="">{{ s.supplierQuoteNumber }}</p>
              </div>
            </div>
            <div class="d-flex pr-3 pl-3">
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Part/s: </p>
                <p class=""></p>
              </div>
              <div class="d-flex col-6 pl-0">
                <p class="font-weight-bold pr-2">Make: </p>
                <p class="">{{ rfq.vehicle.make }}</p>
              </div>
            </div>
            <div v-for="[itm, ptsid] in getNonReturnableParts(s)" class="form-group row pr-3 pl-3">
              <!--            <input-->
              <!--                type="text"-->
              <!--                class="col-12 form-control"-->
              <!--            />-->
              <div class="col-12 col-md-1" style="padding: 0px">
                <label class="form-check-inline checkbox non-returnable-modal-checkbox">
                  <input class="form-check-input" type="checkbox" :value="ptsid"
                         @change="changedNonReturnableSelectedParts" v-model="nonReturnableSelectedParts[s.id]">
                  <span class="icon clickable"><i class='bx bx-check'></i></span>
                  <span class="direct-text"></span>
                </label>
              </div>

              <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control hover-border-effect">
                <b>Part: </b> {{ itm.name }}<span v-if="itm.number"> | {{ itm.number }}</span> <span v-if="itm.qty"> | QTY {{ itm.qty }}</span><span
                  v-if="s.getOffer(itm).price"> | {{ s.getOffer(itm).price | formatMoney }}</span>
              </div>
            </div>
            <div v-if="s.nonReturnableRules.displayTncs" class="form-group row pr-3 pl-3">
              <label class="col col-form-label pl-0">Terms & Conditions</label>
              <div class="disabled-vue-editor-non-returnable">
                <vue-editor
                    :disabled="true"
                    v-model="s.nonReturnableRules.tncsText"
                    :editorToolbar="editorToolbar"></vue-editor>
              </div>
              <!--            <div v-html="settings.acceptance" class="col-12 form-control non-returnable-text-message" type="text" disabled></div>-->
            </div>
            <div v-if="s.nonReturnableRules.requireAcceptTncs && nonReturnableSettings[s.id]"
                 class="w-100 text-right row p-3">
              <label class="form-check-inline checkbox non-returnable-modal-checkbox">
                <input class="form-check-input" type="checkbox" @change="changedNonReturnableSettings"
                       v-model="nonReturnableSettings[s.id].acceptanceOfTics">
                <span class="icon clickable"><i class='bx bx-check'></i></span>
                <span class="direct-text">I have read & accept the suppliers Terms and Conditions</span>
              </label>
            </div>

            <div v-if="s.nonReturnableRules.requireESign" class="form-group row p-3 position-relative"
                 @mouseover="checkSignature('signaturePad-' + s.id)">
              <label class="col col-form-label pl-0">E-Signature</label>
              <template>
                <div class="col-12 p-0 non-returnable-signature-block" :ref="'nonReturnableSignatureBlock-' + s.id">
                  <VPerfectSignature :stroke-options="strokeOptions" :ref="'signaturePad-' + s.id"
                                     class="non-returnable-signature-canvas"/>
                </div>
                <a
                    href="#"
                    class="control-elem-1__item"
                    v-if="!isEmptySignature"
                    @click.prevent="clearSignature('signaturePad-' + s.id)"
                    style="width: 40px"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                  <i style="top: 9px" class="bx bx-trash"></i>
                </a>
              </template>
            </div>
            <template v-if="s.nonReturnableRules.requireNameAndPosition && nonReturnableSettings[s.id]">
              <div class="form-group row pr-3 pl-3">
                <label class="col-4 col-form-label pl-0">Accepted By: </label>
                <input
                    type="text"
                    v-model="nonReturnableSettings[s.id].acceptedBy"
                    @change="changedNonReturnableSettings"
                    class="col-8 form-control"
                />
              </div>
              <div class="form-group row pr-3 pl-3">
                <label class="col-4 col-form-label pl-0">Position Held: </label>
                <input
                    type="text"
                    class="col-8 form-control"
                    @change="changedNonReturnableSettings"
                    v-model="nonReturnableSettings[s.id].positionHeld"
                />
              </div>
            </template>

          </form>
        </div>
        <template v-slot:modal-footer="{ confirm, cancel }">
          <template>
            <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
            <b-button :disabled="isDisabled(s)" class="input-btn decline"
                      @click="declineOrAcceptNonReturnable('decline', s)" style="border: 1px solid #79B1BB !important">
              Decline
            </b-button>
            <b-button :disabled="isDisabled(s)" class="input-btn"
                      @click="declineOrAcceptNonReturnable('accepted', s, ind == 0)"
                      style="background-color: #79B1BB !important; border-color: #79B1BB !important">Accept
            </b-button>
          </template>
        </template>
      </b-modal>
    </template>

  </div>
</template>
<!--<style lang="scss">-->
<!--@import "../../../styles/style-views";-->
<!--</style>-->
<style>
.theme-repairer .rfq-view-page .search-filter-in-header .btn,
.theme-repairer .rfq-view-page .search-filter-in-header .btn-chat {
  margin-right: 0px !important;
  min-width: 118px;
}

.theme-repairer .rfq-view-page .search-filter-in-header .window-chat {
  right: 90px !important;
}

.theme-repairer .rfq-view-page .send-direct-order-comment-block {
  min-height: auto !important;
}

.V3 .non-returnable-modal-checkbox .icon {
  border-color: #d3d3d3 !important;
}

.V3 .non-returnable-modal-checkbox span.icon {
  border-color: #d3d3d3 !important;
}

.V3 .non-returnable-modal-checkbox > input[type="checkbox"]:checked + span.icon {
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}


.V3 .non-returnable-modal-checkbox > input[type="checkbox"]:checked + span.icon i {
  color: white !important;
  /*transform: translate(-2px, -2px) !important;*/
}

.hover-border-effect:hover {
  border: 1px solid rgb(121, 177, 187) !important;
}

.expired-modal-supps-section {
  width: 60%;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 4px;
  padding: 10px;
}

.modal-send-order-to-ibs-btn-no {
  height: 38px !important;
  width: 83px !important;
  font-size: 13px !important;
  font-weight: bold;
  color: #1C1F39 !important;
  border: 1px solid #29BBC1;
  border-radius: 3px;
  background-color: white !important;
  margin-right: 10px;
}

.modal-send-order-to-ibs-btn-no:hover {
  border: 1px solid #29BBC1 !important;
}

.modal-send-order-to-ibs-btn-yes {
  height: 38px !important;
  width: 83px !important;
  font-size: 13px !important;
  font-weight: bold;
  color: #eee !important;
  border: 1px solid #79B1BB;
  border-radius: 3px;
  background-color: #79B1BB !important;
  margin-right: 10px;
}

.modal-send-order-to-ibs-btn-yes:hover {
  color: #1C1F39 !important;
}

.modal-footer {
  margin-top: 10px;
}

.pdf-upload-loader {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  padding-top: 20px;
  margin: 5px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pdf-upload-error {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  margin: 5px;
  padding-top: 20px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdf-upload-error p {
  text-align: center;
  width: 50%;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #1C1F39;
  margin-bottom: 2rem;
}

.pdf-upload-error p span {
  color: red;
}

.repairer-custom-non-returnable-modal .modal-title {
  color: #fff;
  margin-left: 15px;
}

.repairer-custom-non-returnable-modal {
  max-width: 650px !important;
  margin: 7rem auto !important;
}

.special-order-text-message {
  height: 100px !important;
}

.repairer-custom-non-returnable-modal .modal-body {
  padding-bottom: 0;
}

.repairer-custom-non-returnable-modal .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.repairer-custom-non-returnable-modal .modal-header {
  background-color: #79B1BB;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.repairer-custom-non-returnable-modal .modal-content {
  border-radius: 3px !important;
}

.repairer-custom-non-returnable-modal .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #29BBC1 !important;
  border-color: #29BBC1 !important;
}

.repairer-custom-non-returnable-modal .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.repairer-custom-non-returnable-modal .input-btn:focus {
  box-shadow: none !important;
}

.repairer-custom-non-returnable-modal .special-order-signature-canvas {
  height: 100px !important;
  width: 100% !important;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
}

.repairer-custom-non-returnable-modal .dropzone-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .repairer-custom-non-returnable-modal {
    margin: 8rem auto !important;
  }
}

.repairer-custom-non-returnable-modal .control-elem-1__item {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px;
  position: absolute;
  top: 47px;
  right: 20px;
  z-index: 9;
  height: 40px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transition: opacity 150ms ease-in;
  border: 1px solid rgba(27, 30, 56, 0.25);
  cursor: pointer;
  opacity: 0;
}

.repairer-custom-non-returnable-modal .control-elem-1__item:hover {
  opacity: 1;
}

.repairer-custom-non-returnable-modal .control-elem-1__item i {
  font-size: 18px;
  color: #FF5E5E;
}

.theme-repairer .repairer-insurer-multiselect .multiselect__option:after {
  background-color: #e9f8f9 !important;
  color: #79B1BB;
  font-weight: 600;
  height: 10px;
}

.theme-repairer .large-options-width .dropdown-menu {
  width: 244px !important;
}

.theme-repairer .large-options-width .dropdown-options ul.dropdown-menu {
  transform: translate3d(-94px, 37px, 0px) !important;
}


.modal-quote-expired-warning {
  margin-top: 133px !important;
}

@media (max-width: 1246px) {
  .modal-dialog.modal-quote-expired-warning,
  .modal-dialog.modal-send-order-back-to-ibs {
    margin-left: 40% !important
  }
}

@media (max-width: 863px) {
  .modal-dialog.modal-quote-expired-warning,
  .modal-dialog.modal-send-order-back-to-ibs {
    margin-left: 35% !important
  }
}

@media (max-width: 790px) {
  .modal-dialog.modal-quote-expired-warning,
  .modal-dialog.modal-send-order-back-to-ibs {
    margin-left: 31% !important
  }
}

@media (max-width: 767px) {
  .modal-dialog.modal-quote-expired-warning,
  .modal-dialog.modal-send-order-back-to-ibs {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .theme-repairer .large-options-width .dropdown-options ul.dropdown-menu {
    transform: translate3d(-153px, 37px, 0px) !important;
  }
}

.modal-dialog.modal-quote-expired-warning,
.modal-dialog.modal-send-order-back-to-ibs {
  margin-top: 133px !important;
  margin-bottom: 32.75rem !important;
}

.modal-quote-expired-warning .modal-header,
.modal-send-order-back-to-ibs .modal-header {
  background-color: #79B1BB !important;
  color: white !important;
  padding-left: 24px !important;
}

.modal-quote-expired-warning .modal-content,
.modal-send-order-back-to-ibs .modal-content {
  border-radius: 5px;
}

.modal-quote-expired-warning .modal-title,
.modal-send-order-back-to-ibs .modal-title {
  height: 35px;
}

.rfq-view-page .dropdown-options .disabled-button-request-rfq a {
  color: #adb5bd !important;
}

.rfq-view-page .dropdown-options .disabled-button-supplier-update {
  color: #adb5bd !important;
}

.theme-repairer .dropdown-options .dropdown-menu .request-rfq-update-wrapper .dropdown-item {
  height: 46px !important;
  padding: 8px 15px 0 5px !important;
  overflow: hidden;
  white-space: nowrap;
}

.theme-repairer .dropdown-options .dropdown-menu .request-rfq-update-wrapper {
  border-bottom: none;
}
</style>
<style scoped>
.blinking-comment {
  font-weight: 800;
  color: red;
  font-size: 14px;
  line-height: 26px;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

.blinking-comment span {
  font-weight: 500;
}

.dropdown-actions >>> button span {
  border-color: #7bbec0 !important;
}

.dropdown-actions >>> button {
  background-color: #7bbec0 !important;
  color: #fdfdfd !important;
}

.dropdown-actions >>> button:hover {
  background-color: #afd7d8 !important;
  color: #3f3f3f !important;
}

.files-diagram-wrapper {
  max-width: 600px;
}

.repairer-view-files-wrapper {
  flex-direction: row-reverse !important;
  justify-content: start !important;
}

.chat-image-gallery.active {
  z-index: 99999;
  position: absolute;
  top: 0;
}

.chat-image-gallery.active >>> .window-chat.active {
  position: fixed;
  right: 0;
}

.chat-image-gallery.active >>> .window-chat {
  position: fixed;
  right: 0;
}

.block-uploader >>> .dropify-wrapper {
  width: 50% !important;
  float: right;
}

@media screen and (max-width: 1400px) {
  .block-uploader >>> .dropify-wrapper {
    width: 100% !important;
  }
}

.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
}

.comments-blinks-text {
  color: #F59345;
  font-size: 14px;
}

.comments-after-blinks-text {
  color: black;
}

.comments-blinks-text.active {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

.img-code-paint-container {
  max-width: 385px;
  width: 100% !important;
}

.img-code-paint-container img {
  object-fit: cover;
  width: 100%;
}
.modal-undo-part-line-change-btn-cancel {
  width: 100px !important;
}

.modal-undo-part-line-change-btn-update {
  width: 110px !important;
  background-color: #F59345 !important;
  border-color: #F59345 !important;
}

.modal-undo-part-line-change-btn-now {
  width: 110px !important;
  background-color: #17ae58 !important;
  border-color: #17ae58 !important;
}

.modal-undo-part-line-change-btn-cancel:hover {
  background-color: #f8f8f8 !important;
}

.modal-undo-part-line-change-btn-update:hover {
  background-color: #f9be8f !important;
  color: #eee !important;
}

.modal-undo-part-line-change-btn-now:hover {
  background-color: #74ce9b !important;
  border-color: #74ce9b !important;
  color: #eee !important;
}

.bx-error-alt {
  font-size: 16px;
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.error-icon-flashing {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    color: red
  }
}

.rfq-order-button-blinked-orange {
  animation-name: rfq-order-btn-blinked-orange;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.active-ordered-button {
  background-color: #17ae58;
  /*animation-name: rfq-order-btn-blinked-orange;*/
  /*animation-duration: 3s;*/
  /*animation-iteration-count: infinite;*/
}

.V3 .active-ordered-button-2 {
  background-color: #74ce9b !important;
  border-color: #74ce9b !important;
}

@keyframes rfq-order-btn-blinked-orange {
  50% {
    background-color: #F59345;
    border-color: #F59345;
  }
}

.repairer-request-update-context {
  position: absolute;
  right: 158px;
  top: -1px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.repairer-request-update-context-2 {
  right: 242px !important;
}

.repairer-request-update-context .btn {
  border: none;
}

.insurer-text-block {
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 940px;
  left: 840px;
  width: 300px;
  padding: 20px;
  border: 1px solid rgba(27, 30, 56, 0.1);
  border-radius: 3px;
}

.delivery-required-time {
  margin-top: 15px;
  margin-bottom: 15px;
}

.files-diagram-block {
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 30px;
  min-height: 400px;
  max-width: 600px;
}

.diagram-block-image {
  min-height: 325px;
  border: none;
}

.diagram-image {
  min-height: 300px;
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
  cursor: pointer;
}

.diagram-block-line {
  margin-left: 15px;
  margin-right: 15px;
  height: 2px;
  background-color: rgba(27, 30, 56, 0.25);
}

.diagram-block-button {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}


.diagram-block-button span {
  color: rgba(0, 0, 0, .5);
  font-weight: normal;
  margin-left: 15px;
}

@media screen and (min-width: 1200px) {
  .delivery-required-time {
    margin-top: 45px;
  }
}

@media screen and (max-width: 1150px) {
  .vehicle-insurer-block {
    display: none;
  }
}

@media (max-width: 1200px) {
  .repairer-vehicle-block {
    flex-direction: column;
  }

  .vehicle-details-block {
    width: auto;
  }
}

@media (max-width: 768px) {
  .tabs-component .tab-header.float-sm-right div.tabs-nav-right-btn, .tabs-component .tab-header.float-sm-right div.tabs-nav-right-options {
    display: block !important;
  }

  .theme-repairer .dropdown-options {
    width: 90px;
  }

  .theme-repairer .dropdown-options .dropdown-arrow {
    width: 20px;
  }

  .theme-repairer .dropdown-options .dropdown-arrow .bx-down-arrow {
    margin: 10px 10px 10px 5px;
  }
}
</style>
